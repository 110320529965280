import { readonly, ref } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useQuiz from '@/composables/global/use-quiz'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'
import { nonNullish } from '@/utils/types'

import { Level } from '@/models/courses/level'
import { Page } from '@/models/courses/levels/page'
import { Question } from '@/models/courses/levels/pages/question'
import { Choice } from '@/models/courses/levels/pages/questions/choice'
import { Answer } from '@/models/users/answer'

const debug = false

export type PlanItem = {
    levelNum: number
    pageNum: number
    question: Question
    choices?: Choice[]
    answer?: Answer
}

const planItems = ref<PlanItem[]>([])

export default function () {
    const { activeCourse } = useCourses()
    const { viewer } = useViewer()

    async function addPlanResponses(responses: Partial<Answer>[]) {
        logger(debug, responses)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const answersCollection = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('answers')

        const batch = firestore.batch()

        responses.forEach((r) => {
            const newAnswerDoc = answersCollection.doc()
            const data = {
                question: r.question,
                createdAt: serverTimestamp(),
                attempt: (r.attempt ?? 0) + 1,
                ...(r.text ? { text: r.text } : {}),
                ...(r.choices ? { choices: r.choices } : {}),
                ...(r.range ? { range: r.range } : {}),
            }
            batch.set(newAnswerDoc, data)
        })

        await batch.commit()
    }

    async function fetchChoices(
        levelId: string,
        pageId: string,
        questionId: string
    ) {
        logger(debug, questionId)
        if (activeCourse.value?.id === undefined) return undefined

        const choicesSnapshot = await firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .collection('pages')
            .doc(pageId)
            .collection('questions')
            .doc(questionId)
            .collection('choices')
            .orderBy('num')
            .get()

        if (choicesSnapshot.empty) {
            return undefined
        } else {
            return getCollectionDocs<Choice>(choicesSnapshot)
        }
    }

    function sortPlanItemsAscending(a: PlanItem, b: PlanItem) {
        logger(debug)

        // Orders by Level.Page.Question
        if (a.levelNum !== b.levelNum) return a.levelNum - b.levelNum
        else if (a.pageNum !== b.pageNum) return a.pageNum - b.pageNum

        return (a.question.num ?? 0) - (b.question.num ?? 0)
    }

    async function fetchActiveCoursePlanItems() {
        logger(debug)

        const { fetchAnswer } = useQuiz()

        const planItemsSnapshot = await firestore
            .collectionGroup('questions')
            .where('category', '==', 'plan')
            .get()

        if (planItemsSnapshot.empty) {
            planItems.value = []
            return Promise.resolve()
        }

        const activeCoursePlanItemsDocs = planItemsSnapshot.docs.flatMap(
            (doc) => {
                const courseId =
                    doc.ref.parent?.parent?.parent?.parent?.parent?.parent?.id
                if (courseId === activeCourse.value?.id) {
                    return doc
                }
                return []
            }
        )

        const planItemsArray = await Promise.all(
            activeCoursePlanItemsDocs.map(async (doc) => {
                const levelId = doc.ref.parent?.parent?.parent?.parent?.id
                const pageId = doc.ref.parent?.parent?.id
                if (!levelId) return
                if (!pageId) return

                const level = await firestore
                    .collection('courses')
                    .doc(activeCourse.value?.id)
                    .collection('levels')
                    .doc(levelId)
                    .get()
                    .then(docData<Level>)

                if (!level) return

                const page = await firestore
                    .collection('courses')
                    .doc(activeCourse.value?.id)
                    .collection('levels')
                    .doc(levelId)
                    .collection('pages')
                    .doc(pageId)
                    .get()
                    .then(docData<Page>)

                if (!page) return

                const choices = await fetchChoices(levelId, pageId, doc.id)
                const answer = await fetchAnswer(doc.id)

                return {
                    levelNum: level.num,
                    pageNum: page.num,
                    question: docData<Question>(doc),
                    choices,
                    answer,
                }
            })
        )

        planItems.value = planItemsArray.filter(nonNullish)
        planItems.value.sort(sortPlanItemsAscending)
    }

    async function fetchPlanItems() {
        logger(debug)
        if (activeCourse.value === undefined) return

        await fetchActiveCoursePlanItems()
    }

    function deinitPlanPage() {
        logger(debug)
        planItems.value = []
    }

    return {
        // State
        planItems: readonly(planItems),

        // Functions
        addPlanResponses,
        deinitPlanPage,
        fetchPlanItems,
    }
}
