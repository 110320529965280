import {
    FirestoreCollectionRef,
    FirestoreDocument,
    FirestoreQuerySnapshot,
    FirestoreWriteBatch,
    deleteField,
} from '@/firebase-config'

import { isObject, objectKeys } from '@/utils/objects'

export function docData<T>(doc: FirestoreDocument) {
    return { id: doc.id, ...doc.data() } as T
}

export function getCollectionDocs<T>(
    collectionSnapshot: FirestoreQuerySnapshot
) {
    return collectionSnapshot.docs.map(docData<T>)
}

export function generateDocId(collectionRef?: FirestoreCollectionRef) {
    if (collectionRef === undefined) {
        throw 'Unable to generate ID, collectionRef is undefined'
    }

    return collectionRef.doc().id
}

/**
 * Chains operation to a Firestore WriteBatch.
 *
 * Operation: shift num field of documents in range [fromNum:toNum].
 * If fromNum is undefined, shift from beginning of collection. If toNum is
 * undefined, shift to end of collection.
 *
 * @param collectionRef ref pointing to collection whose documents' nums should be incremented
 * @param options specifies range information, shift direction, and other document field filters
 */
export async function shiftDocNums(
    batch: FirestoreWriteBatch,
    collection: FirestoreCollectionRef,
    options: {
        shift: 1 | -1
        fromNum?: number
        toNum?: number
        inBranch?: boolean
        branchRoot?: string
        branchNum?: number
    }
) {
    const { shift, fromNum, toNum, inBranch, branchRoot, branchNum } = options

    // Build collection query
    let collectionQuery = collection.orderBy('num')
    if (fromNum) collectionQuery = collectionQuery.where('num', '>=', fromNum)
    if (toNum) collectionQuery = collectionQuery.where('num', '<=', toNum)
    if (inBranch)
        collectionQuery = collectionQuery.where('inBranch', '==', inBranch)
    if (branchRoot)
        collectionQuery = collectionQuery.where('branchRoot', '==', branchRoot)
    if (branchNum)
        collectionQuery = collectionQuery.where('branchNum', '==', branchNum)

    // Add num updates to batch
    const collectionSnapshot = await collectionQuery.get()
    collectionSnapshot.forEach((doc) => {
        batch.update(doc.ref, { num: doc.get('num') + shift })
    })
}

/**
 * Returns a deep copy of `obj` whose unwanted properties are removed or
 * replaced with a Firestore deletion sentinal. If `op` is "update", nested
 * objects are replaced with dot-notation root-level properties to accomodate
 * Firestore syntax. By default, unwanted properties include `""` `null` and
 * `undefined`. Recursion does not proceed into arrays due to limitations of
 * Firestore.
 *
 * @param op the Firestore operation to clean for
 * @param obj the object to process for Firestore write
 * @param options.keepEmptyStrings whether to keep empty strings; default `false`
 * @param options.keepEmptyObjects whether to keep empty objects; default `true`
 * @param options.keepEmptyArrays whether to keep empty arrays; default `true`
 * @param options.markAllUnwantedFieldsForDeletion whether to mark all unwanted fields for deletion with `deleteField()`; default `false`
 * @param options.deleteFields specific fields to mark for deletion with `deleteField()`
 * @param options.preserveFields specific fields **not** to remove or delete (overrides `options.deleteFields`)
 * @param options._parentField internal use for recursion, do not set manually
 *
 * @returns a copy of the object with unwanted properties removed or set for deletion
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function cleanObjForFirestore<T extends string>(
    op: 'set' | 'update',
    data: Record<T, any> | Partial<Record<T, any>> | any[],
    options: {
        keepEmptyStrings?: boolean
        keepEmptyObjects?: boolean
        keepEmptyArrays?: boolean
        markAllUnwantedFieldsForDeletion?: boolean
        deleteFields?: T[]
        preserveFields?: T[]
        _parentField?: string
    } = {
        keepEmptyStrings: false,
        keepEmptyObjects: true,
        keepEmptyArrays: true,
        markAllUnwantedFieldsForDeletion: false,
    }
) {
    const {
        keepEmptyStrings,
        keepEmptyObjects,
        keepEmptyArrays,
        markAllUnwantedFieldsForDeletion,
        deleteFields,
        preserveFields,
        _parentField,
    } = options

    const useDotNotation = op === 'update'

    if (!isObject(data) || Array.isArray(data)) return data

    let hasWantedValue = false
    let resultObj: any = {}
    const resultArr: any[] = []

    objectKeys(data).forEach((key) => {
        let v = data[key]

        if (isObject(data[key])) {
            // Recursively clean property
            v = cleanObjForFirestore(op, data[key], {
                ...options,
                _parentField: _parentField ? `${_parentField}.${key}` : key,
            })
        }

        const isValueEmptyObject =
            isObject(v) && !Array.isArray(v) && Object.keys(v).length === 0

        const isValueEmptyArray =
            isObject(v) && Array.isArray(v) && v.length === 0

        if (
            !preserveFields?.includes(key) &&
            (v === null ||
                v === undefined ||
                (!keepEmptyObjects && isValueEmptyObject) ||
                (!keepEmptyArrays && isValueEmptyArray) ||
                (!keepEmptyStrings && v === '') ||
                deleteFields?.includes(key))
        ) {
            // Value is unwanted
            if (
                !Array.isArray(data) &&
                (deleteFields?.includes(key) ||
                    markAllUnwantedFieldsForDeletion)
            ) {
                // Mark field for deletion
                resultObj = {
                    ...resultObj,
                    [_parentField && useDotNotation
                        ? `${_parentField}.${key}`
                        : key]: deleteField(),
                }
            }
        } else {
            // Value is wanted
            hasWantedValue = true
            if (Array.isArray(data)) {
                // Parent is array, push value to result array
                resultArr.push(v)
            } else {
                // Parent is object, copy value to result object
                if (
                    isObject(v) &&
                    !Array.isArray(v) &&
                    !isValueEmptyObject &&
                    useDotNotation
                ) {
                    // Value is object and should be spread in parent (effectively deletes empty objects)
                    resultObj = { ...resultObj, ...v }
                } else {
                    // Value is not object or shouldn't be spread in parent
                    resultObj = {
                        ...resultObj,
                        [_parentField && useDotNotation
                            ? `${_parentField}.${key}`
                            : key]: v,
                    }
                }
            }
        }
    })

    if (Array.isArray(data) && hasWantedValue) {
        return resultArr
    } else if (Array.isArray(data) && !hasWantedValue) {
        return []
    } else if (hasWantedValue) {
        return resultObj
    } else {
        return {}
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
}
