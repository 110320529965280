import { compareDesc } from 'date-fns'
import { computed, readonly, ref } from 'vue'

import { firestore, FirestoreQuerySnapshot } from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useQuiz, { QuizItem } from '@/composables/global/use-quiz'

import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import { Question } from '@/models/courses/levels/pages/question'

const debug = false

const summaryQuizItems = ref<QuizItem[]>([])

// Sort quiz items by time of response, unanswered questions are filtered
const sortedSummaryQuizItems = computed(() =>
    summaryQuizItems.value
        .filter((item) => item.answer !== undefined)
        .sort((a, b) =>
            compareDesc(
                b.answer?.createdAt.toDate() ?? new Date(),
                a.answer?.createdAt.toDate() ?? new Date()
            )
        )
)

export default function (levelId?: string) {
    const { activeCourse } = useCourses()
    const {
        fetchAnswer,
        fetchChoices,
        fetchMatchOptions,
        setQuestionCollection,
    } = useQuiz()

    async function getSummaryQuestionsInLevel(
        levelPagesSnapshot: FirestoreQuerySnapshot
    ): Promise<Map<string, Question[]>> {
        const pageToQuestionsMap = new Map<string, Question[]>()

        const summaryQuestionsInLevelPromises = levelPagesSnapshot.docs.map(
            async (pageDoc) => {
                if (!activeCourse.value) throw 'Active course not initialized'
                const summaryQuestionsSnapshot = await firestore
                    .collection('courses')
                    .doc(activeCourse.value.id)
                    .collection('levels')
                    .doc(levelId)
                    .collection('pages')
                    .doc(pageDoc.id)
                    .collection('questions')
                    .where('summary', '==', true)
                    .orderBy('num')
                    .get()

                const retrievedQuestions = getCollectionDocs<Question>(
                    summaryQuestionsSnapshot
                )

                pageToQuestionsMap.set(pageDoc.id, retrievedQuestions)
            }
        )

        await Promise.all(summaryQuestionsInLevelPromises)

        return pageToQuestionsMap
    }

    async function loadSummaryQuizItems() {
        logger(debug)
        if (!activeCourse.value) throw 'Active course not initialized'
        if (levelId === undefined) throw 'Level ID is undefined'

        const levelPagesSnapshot = await firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .collection('pages')
            .get()

        if (levelPagesSnapshot.empty) {
            logger(debug, 'No pages found in level')
            summaryQuizItems.value = []
            return Promise.resolve()
        }

        const pageToQuestionsMap =
            await getSummaryQuestionsInLevel(levelPagesSnapshot)

        const newSummaryQuizItems: QuizItem[] = []

        for await (const [pageId, questions] of pageToQuestionsMap.entries()) {
            setQuestionCollection(levelId, pageId)
            await Promise.all(
                questions.map(async (question) => {
                    const answer = await fetchAnswer(question.id)
                    const choices = await fetchChoices(question.id)
                    const matchOptions = await fetchMatchOptions(question.id)
                    newSummaryQuizItems.push({
                        answer,
                        choices,
                        matchOptions,
                        question,
                    })
                })
            )
        }

        summaryQuizItems.value = newSummaryQuizItems
    }

    function deinitLevelPageQuizSummary() {
        logger(debug)
        summaryQuizItems.value = []
    }

    return {
        summaryQuizItems: readonly(summaryQuizItems),
        sortedSummaryQuizItems,

        deinitLevelPageQuizSummary,
        loadSummaryQuizItems,
    }
}
