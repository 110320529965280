import { computed, readonly, ref } from 'vue'

import appConfig from '@/app-config'
import {
    firestore,
    FirebaseDocumentSnapshot,
    serverTimestamp,
    FirestoreQuerySnapshot,
} from '@/firebase-config'
import Router from '@/router'

import useCourses from '@/composables/global/use-courses'
import useUsers from '@/composables/global/use-users'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'

import { User } from '@/models/user'
import { Log, Platform } from '@/models/users/log'

const debug = false

export type UserLog = {
    log: Log
    user: User
}

type LevelPageDetails = {
    level: string
    page: string
    levelNum: number
    pageNum: number
    inBranch: boolean
    isPassBranch: boolean
    quizScore?: number
    quizPassed?: boolean
}

const USER_LOG_FETCH_COUNT = 15
export const USERS_LOG_FETCH_COUNT = 50

// Single-user
const cachedUserId = ref<string>()
const cachedLogs = ref<Log[]>([])
const userCollectionQueryCursor = ref<FirebaseDocumentSnapshot>()
const sortedLogs = computed(() => {
    return cachedLogs.value.sort((a, b) => {
        return b.createdAt.toMillis() - a.createdAt.toMillis()
    })
})

const allLogsOfParticipantsFetched = ref(false)
const logsOfParticipants = ref<UserLog[]>([])
const usersCollectionQueryCursor = ref<FirebaseDocumentSnapshot>()
const sortedLogsOfParticipants = computed(() => {
    return logsOfParticipants.value.sort((a, b) => {
        return b.log.createdAt.toMillis() - a.log.createdAt.toMillis()
    })
})

export default function () {
    const { activeCourse } = useCourses()
    const { getUser } = useUsers()
    const { viewer, isOnAndroid, isOnIos, isOnWeb } = useViewer()

    const canFetchMoreUserLogs = computed(
        () => userCollectionQueryCursor.value !== undefined
    )

    const canFetchMoreUsersLogs = computed(
        () => usersCollectionQueryCursor.value !== undefined
    )

    async function addLog(options?: {
        isActive?: boolean
        levelPageDetails?: LevelPageDetails
    }) {
        logger(debug, options)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (activeCourse.value === undefined)
            throw 'Active course not initialized'

        let data: Partial<Log> = {
            appVersion: appConfig.version,
            course: activeCourse.value.id,
            text: Router.currentRoute.value.fullPath,
            platform: isOnAndroid.value
                ? 'android'
                : isOnIos.value
                  ? 'ios'
                  : isOnWeb.value
                    ? 'web'
                    : undefined,
            ...(options?.isActive !== undefined && {
                isActive: options.isActive,
            }),
        }

        if (options?.levelPageDetails) {
            data = { ...data, ...options.levelPageDetails }
        }

        return firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('logs')
            .add({
                ...data,
                createdAt: serverTimestamp(),
            })
    }

    async function handleMultiUserLogsSnapshot(
        logsSnapshot: FirestoreQuerySnapshot
    ) {
        logger(debug)

        const newParticipantLogs = logsSnapshot.docs
            .map((doc) => {
                const userId = doc.ref.parent?.parent?.id
                if (!userId) return

                const user = getUser(userId)
                if (!user) return

                if (user.researchId === undefined || user.researchId === '')
                    return
                if (user.status === 'staff') return

                const logDoc = docData<Log>(doc)
                if (logDoc.createdAt === null) return // document is being created
                return { log: logDoc, user }
            })
            .filter((doc): doc is UserLog => !!doc)

        return newParticipantLogs
    }

    async function initLogsOfParticipants() {
        logger(debug)
        if (activeCourse.value === undefined)
            throw 'Active course is not initialized'

        const logsSnapshot = await firestore
            .collectionGroup('logs')
            .where('course', '==', activeCourse.value.id)
            .limit(USERS_LOG_FETCH_COUNT)
            .orderBy('createdAt', 'desc')
            .get()

        const newParticipantLogs =
            await handleMultiUserLogsSnapshot(logsSnapshot)
        logsOfParticipants.value = [
            ...logsOfParticipants.value,
            ...newParticipantLogs,
        ]

        const logsSnapshotSize = logsSnapshot.docs.length
        if (logsSnapshotSize === USERS_LOG_FETCH_COUNT) {
            usersCollectionQueryCursor.value =
                logsSnapshot.docs[logsSnapshotSize - 1]
        } else {
            usersCollectionQueryCursor.value = undefined
        }
    }

    async function fetchOlderLogsOfParticipants(
        { fetchAll } = { fetchAll: false }
    ) {
        logger(debug)
        if (activeCourse.value === undefined)
            throw 'Active course not initialized'
        if (!usersCollectionQueryCursor.value) throw 'No more logs to fetch'

        const logsCollectionQuery = firestore
            .collectionGroup('logs')
            .where('course', '==', activeCourse.value.id)
            .orderBy('createdAt', 'desc')
            .startAfter(usersCollectionQueryCursor.value)

        let logsSnapshot: FirestoreQuerySnapshot

        if (fetchAll) {
            logsSnapshot = await logsCollectionQuery.get()
        } else {
            logsSnapshot = await logsCollectionQuery
                .limit(USERS_LOG_FETCH_COUNT)
                .get()
        }

        const olderParticipantLogs =
            await handleMultiUserLogsSnapshot(logsSnapshot)

        logsOfParticipants.value = [
            ...logsOfParticipants.value,
            ...olderParticipantLogs,
        ]

        if (fetchAll) {
            usersCollectionQueryCursor.value = undefined
            allLogsOfParticipantsFetched.value = true
            return olderParticipantLogs.length
        }

        const logsSnapshotSize = logsSnapshot.docs.length
        if (logsSnapshotSize === USERS_LOG_FETCH_COUNT) {
            usersCollectionQueryCursor.value =
                logsSnapshot.docs[logsSnapshotSize - 1]
        } else {
            usersCollectionQueryCursor.value = undefined
        }

        return olderParticipantLogs.length
    }

    // Fetch single user subcollection
    async function initUserLogsQuery(
        userId: string,
        platform: Platform | 'all'
    ) {
        logger(debug, { userId, platform })
        if (activeCourse.value === undefined)
            throw 'Active course not initialized'

        cachedUserId.value = userId
        const logsCollection = firestore
            .collection('users')
            .doc(userId)
            .collection('logs')
            .orderBy('createdAt', 'desc')
            .where('course', '==', activeCourse.value.id)

        let logsSnapshot = undefined

        if (platform === 'all') {
            logsSnapshot = await logsCollection
                .limit(USER_LOG_FETCH_COUNT)
                .get()
        } else {
            logsSnapshot = await logsCollection
                .where('platform', '==', platform)
                .limit(USER_LOG_FETCH_COUNT)
                .get()
        }

        cachedLogs.value = getCollectionDocs<Log>(logsSnapshot)

        const logsSnapshotSize = logsSnapshot.docs.length
        if (logsSnapshotSize === USER_LOG_FETCH_COUNT) {
            userCollectionQueryCursor.value =
                logsSnapshot.docs[logsSnapshotSize - 1]
        } else {
            userCollectionQueryCursor.value = undefined
        }
    }

    async function fetchOlderUserLogs(platform: Platform | 'all') {
        logger(debug)
        if (!cachedUserId.value) throw 'User not initialized'
        if (activeCourse.value === undefined)
            throw 'Active course not initialized'
        if (!userCollectionQueryCursor.value) return

        const logsRef = firestore
            .collection('users')
            .doc(cachedUserId.value)
            .collection('logs')
            .orderBy('createdAt', 'desc')
            .where('course', '==', activeCourse.value.id)

        let logsSnapshot = undefined

        if (platform === 'all') {
            logsSnapshot = await logsRef
                .startAfter(userCollectionQueryCursor.value)
                .limit(USER_LOG_FETCH_COUNT)
                .get()
        } else {
            logsSnapshot = await logsRef
                .where('platform', '==', platform)
                .startAfter(userCollectionQueryCursor.value)
                .limit(USER_LOG_FETCH_COUNT)
                .get()
        }

        const retrievedLogs = getCollectionDocs<Log>(logsSnapshot)
        cachedLogs.value = [...cachedLogs.value, ...retrievedLogs]

        const logsSnapshotSize = logsSnapshot.docs.length
        if (logsSnapshotSize === USER_LOG_FETCH_COUNT) {
            userCollectionQueryCursor.value =
                logsSnapshot.docs[logsSnapshotSize - 1]
        } else {
            userCollectionQueryCursor.value = undefined
        }
    }

    function deinitLogs() {
        logger(debug)

        // single-user
        cachedUserId.value = undefined
        cachedLogs.value = []
        userCollectionQueryCursor.value = undefined

        // multi-user
        allLogsOfParticipantsFetched.value = false
        logsOfParticipants.value = []
        usersCollectionQueryCursor.value = undefined
    }

    return {
        allLogsOfParticipantsFetched,
        canFetchMoreUserLogs,
        canFetchMoreUsersLogs,
        logs: readonly(cachedLogs),
        logsOfParticipants: readonly(logsOfParticipants),
        sortedLogs,
        sortedLogsOfParticipants,
        userId: readonly(cachedUserId),

        addLog,
        deinitLogs,
        fetchOlderLogsOfParticipants,
        fetchOlderUserLogs,
        initLogsOfParticipants,
        initUserLogsQuery,
    }
}
