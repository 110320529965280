import { readonly, ref } from 'vue'

import {
    firestore,
    FirestoreCollectionRef,
    FirestoreWriteBatch,
    serverTimestamp,
} from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useViewer from '@/composables/global/use-viewer'

import { UploadResult } from '@/composables/use-storage-upload'

import { logger } from '@/utils/debug'
import { docData, cleanObjForFirestore } from '@/utils/firestore'

import {
    feedbackIds,
    FeedbackMedia,
    FeedbackId,
} from '@/models/courses/levels/pages/feedback-media'

const debug = false

export type QuizFeedbackMedia = {
    [id in FeedbackId]?: FeedbackMedia<id>
}

export type QuizFeedbackMediaForm = {
    [id in FeedbackId]?: Partial<FeedbackMedia<id>> & { id: id }
}

const feedbackMedia = ref<QuizFeedbackMedia>({})

export default function (levelId?: string, pageId?: string) {
    const { activeCourse } = useCourses()
    const { viewer } = useViewer()

    // Collection

    let feedbackMediaCollection: FirestoreCollectionRef | undefined = undefined
    function setFeedbackMediaCollection(levelId: string, pageId: string) {
        logger(debug, levelId, pageId)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        feedbackMediaCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .collection('pages')
            .doc(pageId)
            .collection('feedback-media')
    }

    if (
        activeCourse.value?.id !== undefined &&
        levelId !== undefined &&
        pageId !== undefined
    ) {
        setFeedbackMediaCollection(levelId, pageId)
    }

    // Cleanup

    function deinitLevelPageFeedbackMedia() {
        logger(debug)
        feedbackMedia.value = {}
    }

    async function fetchFeedbackMedia() {
        logger(debug)

        const updatedFeedbackMediaPromises = feedbackIds.map(async (id) => {
            if (feedbackMediaCollection === undefined) {
                throw 'No feedback media collection specified'
            }

            const feedbackMediaDoc = await feedbackMediaCollection.doc(id).get()

            return feedbackMediaDoc.exists
                ? docData<FeedbackMedia>(feedbackMediaDoc)
                : undefined
        })

        const updatedFeedbackMedia = await Promise.all(
            updatedFeedbackMediaPromises
        )

        feedbackMedia.value = updatedFeedbackMedia.reduce(
            (acc, media) => (media ? { ...acc, [media.id]: media } : acc),
            {}
        )
    }

    function batchSetFeedbackMedia(
        feedbackMedia: FeedbackMedia,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, feedbackMedia)

        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (feedbackMediaCollection === undefined)
            throw 'No feedback media collection specified'
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        batch.set(feedbackMediaCollection.doc(feedbackMedia.id), {
            ...cleanObjForFirestore('set', feedbackMedia),
            updatedBy: viewer.value.id,
            updatedAt: serverTimestamp(),
        })
    }

    function batchDeleteFeedbackMedia(
        id: FeedbackId,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, id)

        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (feedbackMediaCollection === undefined)
            throw 'No feedback media collection specified'

        const feedbackMediaRef = feedbackMediaCollection.doc(id)
        batch.delete(feedbackMediaRef)
    }

    function batchUpdateFeedbackMedia(
        updatedFeedbackMedia: QuizFeedbackMedia,
        uploadResults: (UploadResult | undefined)[],
        batch: FirestoreWriteBatch
    ) {
        logger(debug, updatedFeedbackMedia)

        feedbackIds.forEach((mediaId) => {
            const updatedFeedback = updatedFeedbackMedia[mediaId]
            if (updatedFeedback === undefined) {
                batchDeleteFeedbackMedia(mediaId, batch)
            } else {
                const uploadResult = uploadResults?.find(
                    (result) => result?.name === updatedFeedback.id
                )

                if (uploadResult !== undefined) {
                    updatedFeedback.file = uploadResult.downloadUrl.toString()
                }

                batchSetFeedbackMedia(updatedFeedback, batch)
            }
        })

        // Update cached media
        feedbackMedia.value = updatedFeedbackMedia
    }

    function batchUpdateFeedbackMediaCaptions(
        updatedFeedbackMedia: QuizFeedbackMedia,
        uploadResults: (UploadResult | undefined)[],
        batch: FirestoreWriteBatch
    ) {
        logger(debug, updatedFeedbackMedia)

        feedbackIds.forEach((mediaId) => {
            const updatedFeedback = updatedFeedbackMedia[mediaId]
            if (updatedFeedback === undefined) {
                batchDeleteFeedbackMedia(mediaId, batch)
            } else {
                const uploadResult = uploadResults?.find(
                    (result) => result?.name === `${updatedFeedback.id}-caption`
                )

                if (uploadResult !== undefined) {
                    updatedFeedback.closedCaptionFile =
                        uploadResult.downloadUrl.toString()
                }

                batchSetFeedbackMedia(updatedFeedback, batch)
            }
        })

        // Update cached media
        feedbackMedia.value = updatedFeedbackMedia
    }

    return {
        feedbackMedia: readonly(feedbackMedia),

        batchDeleteFeedbackMedia,
        batchSetFeedbackMedia,
        batchUpdateFeedbackMedia,
        batchUpdateFeedbackMediaCaptions,
        deinitLevelPageFeedbackMedia,
        fetchFeedbackMedia,
        setFeedbackMediaCollection,
    }
}
