/* eslint-disable @typescript-eslint/no-explicit-any */

import { logger } from '@/utils/debug'

const debug = false

export function isObject(object: any) {
    logger(debug, object)
    return object != null && typeof object === 'object'
}

/**
 * Recursively removes properties with empty string/array and null/undefined values from object.
 *
 * @param obj
 * @returns the original object with cleaned properties
 */
export function cleanObject(obj: any) {
    logger(debug, obj)

    Object.entries(obj).forEach(([k, v]) => {
        if (isObject(v)) {
            cleanObject(v)
        }
        if (
            (v && typeof v === 'object' && !Object.keys(v).length) ||
            v === '' ||
            v === null ||
            v === undefined
        ) {
            if (Array.isArray(obj)) {
                obj.splice(parseInt(k), 1)
            } else {
                delete obj[k]
            }
        }
    })
    return obj
}

/**
 * Recursive deep object comparison utility.
 *
 * @param object1 an object of comparison
 * @param object2 an object of comparison
 * @returns true if object1 and object2 have deep equality
 */
export function deepEquals(object1: any, object2: any) {
    logger(debug, object1, object2)

    if (!isObject(object1) || !isObject(object2)) return object1 === object2

    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
        logger(debug, 'different keys length', keys1, keys2)
        return false
    }

    for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = isObject(val1) && isObject(val2)
        if (
            (areObjects && !deepEquals(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            logger(debug, 'does not equal at key:', key, val1, val2)
            return false
        }
    }

    return true
}

/**
 * Object mapping utility
 *
 * https://github.com/jquense/yup/issues/130#issuecomment-578392176
 *
 * @param obj
 * @param newValue new value to be assigned to all properties in obj
 * @returns a new object populated with properties replaced with newValue
 */
export function mapRules<T>(
    obj: Record<string, any> | undefined,
    newValue: T
): Record<string, T> {
    if (!obj) return {}
    return Object.keys(obj).reduce(
        (newObj, key) => ({ ...newObj, [key]: newValue }),
        {}
    )
}

/**
 * Compares two objects and returns an object containing the values from the second object
 * for keys where the values are different between the two objects.
 * @param obj1 The first object to compare.
 * @param obj2 The second object to compare.
 * @returns An object containing the keys and values that are different between the two objects.
 */
export function getDifferentValues<T extends object>(
    obj1: T,
    obj2: Partial<T>
) {
    const result: Partial<T> = {}

    for (const key in obj2) {
        if (
            !Object.prototype.hasOwnProperty.call(obj1, key) ||
            !deepEquals(obj1[key], obj2[key])
        ) {
            result[key] = obj2[key]
        }
    }

    return result
}

/**
 * Omit properties from an object
 * @param target The object to omit properties from
 * @param props The properties to omit
 * @returns A new object with the specified properties omitted
 */
export function omitProperties<T extends object, ArrayT extends (keyof T)[]>(
    target: T,
    ...props: ArrayT
) {
    return Object.entries(target).reduce((acc, [key, value]) => {
        if (!props.includes(key as keyof T)) acc[key as keyof T] = value
        return acc
    }, {} as Partial<T>) as Omit<T, ArrayT[number]>
}

/**
 * Object.keys with a more specific return type.
 */
export function objectKeys<T extends object>(object: T) {
    return Object.keys(object) as Array<keyof T>
}

/**
 * Object.entries with a more specific return type.
 */
export function objectEntries<T extends object>(object: T) {
    return Object.entries(object) as Array<
        { [K in keyof T]: [K, T[K]] }[keyof T]
    >
}

/**
 * Object.fromEntries with a more specific return type.
 * @param entries An array of key-value pairs.
 * @returns An object with the specified key-value pairs.
 */
export const objectFromEntries = <
    const T extends ReadonlyArray<readonly [PropertyKey, unknown]>,
>(
    entries: T
): { [K in T[number] as K[0]]: K[1] } => {
    return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] }
}
