import { readonly, ref } from 'vue'

import {
    firestore,
    FirestoreCollectionRef,
    FirestoreWriteBatch,
    serverTimestamp,
} from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useViewer from '@/composables/global/use-viewer'

import { UploadResult } from '@/composables/use-storage-upload'

import { logger } from '@/utils/debug'
import {
    cleanObjForFirestore,
    generateDocId,
    getCollectionDocs,
} from '@/utils/firestore'

import { Element } from '@/models/courses/levels/pages/element'

const debug = false

const elements = ref<Element[]>([])

export default function (levelId?: string, pageId?: string) {
    const { activeCourse } = useCourses()
    const { viewer } = useViewer()

    // Collection

    let elementsCollection: FirestoreCollectionRef | undefined = undefined
    function setElementsCollection(levelId: string, pageId: string) {
        logger(debug, levelId, pageId)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        elementsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .collection('pages')
            .doc(pageId)
            .collection('elements')
    }

    if (
        activeCourse.value?.id !== undefined &&
        levelId !== undefined &&
        pageId !== undefined
    ) {
        setElementsCollection(levelId, pageId)
    }

    // Cleanup

    function deinitLevelPageElements() {
        logger(debug)
        elements.value = []
    }

    // CRUD

    async function fetchElements() {
        logger(debug)
        if (elementsCollection === undefined)
            throw 'No element collection specified'

        const elementsSnapshot = await elementsCollection.orderBy('num').get()
        elements.value = getCollectionDocs<Element>(elementsSnapshot)
    }

    async function batchSetElement(
        element: Element,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, element)

        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (elementsCollection === undefined) {
            throw 'No elements collection specified'
        }
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        batch.set(elementsCollection.doc(element.id), {
            ...cleanObjForFirestore('set', element),
            updatedBy: viewer.value.id,
            updatedAt: serverTimestamp(),
        })
    }

    function batchDeleteElement(element: Element, batch: FirestoreWriteBatch) {
        logger(debug, element)

        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (elementsCollection === undefined) {
            throw 'No elements collection specified'
        }
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const elementRef = elementsCollection.doc(element.id)

        batch.delete(elementRef)
    }

    function getElementsToDelete(updatedElements: Element[]) {
        logger(debug, updatedElements)

        return elements.value.flatMap((cachedElement) => {
            const isDeleted = updatedElements.every(
                (updatedElement) => updatedElement.id !== cachedElement.id
            )

            return isDeleted ? [cachedElement] : []
        })
    }

    function batchUpdateElements(
        updatedElements: Element[],
        uploadResults: (UploadResult | undefined)[],
        batch: FirestoreWriteBatch
    ) {
        logger(debug, updatedElements)

        updatedElements.forEach((updatedElement) => {
            const uploadResult = uploadResults?.find(
                (result) => result?.name === updatedElement.id
            )

            if (uploadResult !== undefined) {
                updatedElement.file = uploadResult.downloadUrl.toString()
            }

            batchSetElement(updatedElement, batch)
        })

        const elementsToDelete = getElementsToDelete(updatedElements)

        // Delete removed elements
        elementsToDelete.forEach((el) => batchDeleteElement(el, batch))

        // Update cached elements
        elements.value = updatedElements

        // Sort updated cached elements
        elements.value.sort((el1, el2) => el1.num - el2.num)
    }

    function batchUpdateElementCaptions(
        updatedElements: Element[],
        uploadResults: (UploadResult | undefined)[],
        batch: FirestoreWriteBatch
    ) {
        logger(debug, updatedElements)

        updatedElements.forEach((updatedElement) => {
            const uploadResult = uploadResults?.find(
                (result) => result?.name === `${updatedElement.id}-caption`
            )

            if (uploadResult !== undefined) {
                updatedElement.closedCaptionFile =
                    uploadResult.downloadUrl.toString()
            }

            batchSetElement(updatedElement, batch)
        })

        // Update cached elements
        elements.value = updatedElements
    }

    function batchUpdateElementTranscriptAudioFiles(
        updatedElements: Element[],
        uploadResults: (UploadResult | undefined)[],
        batch: FirestoreWriteBatch
    ) {
        logger(debug, updatedElements)

        updatedElements.forEach((updatedElement) => {
            const uploadResult = uploadResults?.find(
                (result) =>
                    result?.name === `${updatedElement.id}-transcript-audio`
            )
            if (uploadResult !== undefined) {
                updatedElement.transcriptAudioFile =
                    uploadResult.downloadUrl.toString()
            }
            batchSetElement(updatedElement, batch)
        })

        // Update cached elements
        elements.value = updatedElements
    }

    // Other

    function generateElementId() {
        logger(debug)
        return generateDocId(elementsCollection)
    }

    return {
        elements: readonly(elements),

        batchUpdateElements,
        batchUpdateElementCaptions,
        batchUpdateElementTranscriptAudioFiles,
        deinitLevelPageElements,
        fetchElements,
        generateElementId,
        setElementsCollection,
    }
}
