export type LevelSteps = {
    levelNum: number
    steps: Array<Step>
}

export type Step = {
    id: string
    image: string
    text: string
    textEs: string
    tips: string[]
    tipsEs: string[]
    meditationVideo: string
    meditationAudio: string
}

export const nextSteps: LevelSteps[] = [
    /* Module 2 */
    {
        levelNum: 2,
        steps: [
            {
                id: '02_keep_toothbrushes_household',
                image: 'BR2S Phase II RNS Icons-01.png',
                text: 'Keep child-sized toothbrushes at every household.',
                textEs: 'Tener cepillos de dientes para niños en cada hogar de mi hijo/a.',
                tips: [
                    'We bought a value pack with 5 brushes.',
                    'I put toothbrushes on my grocery list.',
                    'I let my kids choose brushes they like.',
                ],
                tipsEs: [
                    'Compramos un paquete económico con 5 cepillos.',
                    'Puse cepillos de dientes en mi lista de compras.',
                    'Dejo que mis hijos elijan los cepillos que les gusten.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '02_keep_toothbrushes_household.mp3',
            },

            {
                id: '02_keep_toothpaste_household',
                image: 'BR2S Phase II RNS Icons-02.png',
                text: 'Keep children’s fluoride toothpaste at every household (brand doesn’t matter).',
                textEs: 'Tener pasta dental con flúor para niños en todos los hogares (la marca no importa).',
                tips: [
                    'We buy value packs of toothpaste so we never run out. ',
                    'We ask for extra toothpaste when we go to the dentist.',
                    'I let my kids choose the toothpaste they like. ',
                ],
                tipsEs: [
                    'Compramos paquetes económicos de pasta de dientes para que nunca se nos acabe. ',
                    'Pedimos pasta dental extra cuando vamos al dentista.',
                    'Dejo que mis hijos elijan la pasta de dientes que les guste. ',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '02_keep_toothpaste_household.mp3',
            },
            {
                id: '02_talk_about_sugar',
                image: 'BR2S Phase II RNS Icons-03.png',
                text: 'Talk to my child about sugar bugs and how to prevent cavities.',
                textEs: 'Hablar con mi hijo/a sobre las bacterias azucareras y cómo prevenir las caries.',
                tips: [
                    'We watched a youtube video for kids about sugar bugs.',
                    'I talk with my son about brushing away the sugar bugs.',
                    'We got a book from the library about preventing cavities.',
                ],
                tipsEs: [
                    'Vimos un video de youtube para niños sobre insectos del azúcar.',
                    'Hablo con mi hijo sobre eliminar los insectos del azúcar.',
                    'Tenemos un libro de la biblioteca sobre la prevención de las caries.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '02_talk_about_sugar.mp3',
            },
            {
                id: '02_avoid_sharing_toothbrushes',
                image: 'BR2S Phase II RNS Icons-04.png',
                text: 'Avoid sharing spoons, toothbrushes, etc.',
                textEs: 'Evitar compartir cucharas, cepillos de dientes, etc.',
                tips: [
                    'I remind myself that it passes cavity-causing bacteria.',
                    'I tell my kids WHY we don’t share spoons.',
                    'I give myself a pat on the back when I stop myself from sharing spoons.',
                ],
                tipsEs: [
                    'Me recuerdo a mí mismo que esos objectos transmiten bacterias que causan caries.',
                    'Les digo a mis hijos POR QUÉ no compartimos cucharas',
                    'Me doy una palmadita en la espalda cuando me detengo de compartir cucharas.',
                ],
                meditationVideo: 'Brts Mindfulness - 17 Seconds.mp4',
                meditationAudio: '02_avoid_sharing_toothbrushes.mp3',
            },
            {
                id: '02_avoid_dipping_pacifiers',
                image: 'BR2S Phase II RNS Icons-05.png',
                text: 'Avoid dipping pacifiers in sugar, syrup, or honey.',
                textEs: 'Evitar mojar los chupetes en azúcar, almíbar o miel.',
                tips: [
                    'When my baby is crying, I take him outside for fresh air to calm him down.',
                    'I sing to my son to settle him down.',
                    'I remind myself that sugar is harmful to my baby’s little teeth.',
                ],
                tipsEs: [
                    'Cuando mi bebé está llorando, lo llevo afuera a tomar aire fresco para calmarlo.',
                    'Yo le canto a mi hijo para que se calme.',
                    'Me recuerdo a mí misma que el azúcar es dañina para los dientes de mi bebé.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '02_avoid_dipping_pacifiers.mp3',
            },
        ],
    },
    /* Module 3 */
    {
        levelNum: 3,
        steps: [
            /* Module 3 */
            {
                id: '03_have_adult_toothbrush',
                image: 'BR2S Phase II RNS Icons-06.png',
                text: 'Have adult toothbrush available at kid’s brushing spot.',
                textEs: 'Tenga uno de tus cepillos de dientes disponible en el lugar de cepillado de su hijo/a.',
                tips: [
                    'I let my daughter "be in charge" of brushing my teeth.',
                    'I start toothbrushing by asking my son to brush my teeth.',
                    'If I let him brush mine first, he gets excited about it. ',
                ],
                tipsEs: [
                    'Dejo que mi hija "se encargue" de cepillarme los dientes.',
                    'Comienzo a cepillarme los dientes pidiéndole a mi hijo que me los cepille',
                    'Si dejo que él me cepille los míos primero, se emocionará. ',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '03_have_adult_toothbrush.mp3',
            },
            {
                id: '03_invite_toy_brush',
                image: 'BR2S Phase II RNS Icons-07.png',
                text: 'Invite a toy or imaginary buddy to brush with us.',
                textEs: 'Invitar a un juguete o amiguito imaginario a cepillarse con nosotros.',
                tips: [
                    'We keep an action figure in the bathroom for toothbrushing time.',
                    'I tell my son to pick out a toy so he can brush its teeth.',
                    'We brush the toy’s teeth first.',
                ],
                tipsEs: [
                    'Mantenemos algún muñeco coleccionable en el baño para la hora del cepillado de dientes.',
                    'Le digo a mi hijo que escoja un juguete para que pueda cepillarse los dientes.',
                    'Primero le cepillamos los dientes al juguete.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '03_invite_toy_brush.mp3',
            },
            {
                id: '03_brushing_last_food',
                image: 'BR2S Phase II RNS Icons-08.png',
                text: 'Take turns brushing in the evening, after last food/drink of the day.',
                textEs: 'Tomar turnos para cepillarse los dientes por la noche, después de la última comida / bebida del día.',
                tips: [
                    'We brush after dinner. Before we get too tired…',
                    'Brush, book, bed. Easy to remember :)',
                    'I set a daily notification on my phone. It reminds both of us.',
                ],
                tipsEs: [
                    'Nos cepillamos después de la cena. Antes de que nos cansemos demasiado…',
                    'Cepillo, libro, cama. Fácil de recordar :)',
                    'Configuré una notificación diaria en mi teléfono. Nos recuerda a los dos.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '03_brushing_last_food.mp3',
            },
            {
                id: '03_turns_brushing_morning',
                image: 'BR2S Phase II RNS Icons-09.png',
                text: 'Take turns brushing in the morning, after breakfast. ',
                textEs: 'Tomar turnos para cepillarse los dientes por la mañana, después del desayuno.',
                tips: [
                    'I put her toothbrush and paste on the table so we brush right after eating breakfast.',
                    'I set a morning routine--breakfast, brush, backpack.',
                    'I set a daily notification on my phone. It reminds both of us.',
                ],
                tipsEs: [
                    'Puse su cepillo de dientes y pasta sobre la mesa para que nos cepilláramos justo después de desayunar.',
                    'Establecí una rutina matutina: desayuno, cepillado, empacar.',
                    'Configuré una notificación diaria en mi teléfono. Nos recuerda a los dos.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '03_turns_brushing_morning.mp3',
            },
            {
                id: '03_brush_circular_motions',
                image: 'BR2S Phase II RNS Icons-10.png',
                text: 'Brush in gentle circular motions.',
                textEs: 'Cepillar con movimientos circulares suaves.',
                tips: [
                    'I put a sticky note on the bathtoom mirror to remind me.',
                    'We made up a little song about brushing in circles.',
                    'I kept reminding myself that it takes practice.',
                ],
                tipsEs: [
                    'Puse una nota adhesiva en el espejo del baño para recordármelo.',
                    'Inventamos una pequeña canción sobre cepillarse en círculos.',
                    'Me recordaba a mí mismo que se necesita práctica.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '03_brush_circular_motions.mp3',
            },
            {
                id: '03_aim_minutes_brushing',
                image: 'BR2S Phase II RNS Icons-11.png',
                text: 'Aim for 2-minutes of brushing. ',
                textEs: 'Intentar cepillar por 2 minutos.',
                tips: [
                    'I gave myself permission to work up to 2-minutes.',
                    'We listened to a 2-minute song.',
                    'We use a timer.',
                ],
                tipsEs: [
                    'Me di permiso para trabajar en eso hasta 2 minutos.',
                    'Escuchamos una canción de 2 minutos.',
                    'Usamos un temporizador.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '03_aim_minutes_brushing.mp3',
            },
            {
                id: '03_babies_tiny_toothpaste',
                image: 'BR2S Phase II RNS Icons-12.png',
                text: 'For babies, wipe a tiny smear of fluoride toothpaste on their teeth, after last breastfeeding/bottle of the day.',
                textEs: 'Para los bebés, poner una pequeña gota de pasta dental con flúor en sus dientes, después de la última alimentación del día.',
                tips: [
                    'I think about how I’m protecting her little teeth.',
                    'I give him a special toy to play with when I wipe his teeth.',
                    'We sing her favorite song while I wipe her teeth.',
                ],
                tipsEs: [
                    'Pienso en cómo estoy protegiendo sus pequeños dientes.',
                    'Le doy un juguete especial para jugar cuando le limpio los dientes.',
                    'Le cantamos su canción favorita mientras le limpio los dientes.',
                ],
                meditationVideo: 'Brts Mindfulness - 17 Seconds.mp4',
                meditationAudio: '03_babies_tiny_toothpaste.mp3',
            },
        ],
    },
    /* Module 4 */
    {
        levelNum: 4,
        steps: [
            {
                id: '04_regular_times_toothbrushing',
                image: 'BR2S Phase II RNS Icons-13.png',
                text: 'Choose regular times and signals for toothbrushing.',
                textEs: 'Elegir horarios y señales regulares para cepillarse los dientes.',
                tips: [
                    'I set notifications on my phone for morning and evening brushing.',
                    'Setting a 5-minute timer really helps my kids cooperate.',
                    'I like using a timer because then I don’t have to nag--the timer tells them.',
                ],
                tipsEs: [
                    'Configuré notificaciones en mi teléfono para el cepillado matutino y vespertino.',
                    'Configurar un temporizador de 5 minutos realmente ayuda a mis hijos a cooperar.',
                    'Me gusta usar un cronómetro porque así no tengo que regañarlos, el cronómetro les dice',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '04_regular_times_toothbrushing.mp3',
            },
            {
                id: '04_combine_toothbrushing_play',
                image: 'BR2S Phase II RNS Icons-14.png',
                text: 'Combine toothbrushing with play.',
                textEs: 'Combinar el cepillado de dientes con el juego.',
                tips: [
                    'I let myself get goofy…',
                    'She’s really good at make believe, so I just let her lead that part.',
                    'We pretend his teeth are going through a car wash.',
                ],
                tipsEs: [
                    'Me dejo hacer el tonto...',
                    'Ella es muy buena para imaginar, así que la dejé liderar esa parte',
                    'Pretendemos que sus dientes están pasando por un lavado de autos.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '04_combine_toothbrushing_play.mp3',
            },
            {
                id: '04_ignore_minor_misbehavior',
                image: 'BR2S Phase II RNS Icons-15.png',
                text: 'Ignore minor misbehavior while brushing.',
                textEs: 'Ignorar las malas conductas menores mientras cepillamos.',
                tips: [
                    'I do slow breathing.',
                    'I remind myself that giving attention to misbehavior just encourages it.',
                    'I say to myself, "Focus on the positive and ignore the negative."',
                ],
                tipsEs: [
                    'Respiro lento.',
                    'Me recuerdo a mí mismo que prestar atención al mal comportamiento solo lo alienta.',
                    'Me digo a mí mismo: "Enfócate en lo positivo e ignora lo negativo".',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '04_ignore_minor_misbehavior.mp3',
            },
            {
                id: '04_describe_child_doing',
                image: 'BR2S Phase II RNS Icons-16.png',
                text: 'Describe what my child is doing well while brushing.',
                textEs: 'Describir lo que mi hijo/a hace bien mientras cepillamos.',
                tips: [
                    'I think about shining light on behaviors I want to grow, like they’re a plant.',
                    'I make it a game for myself to say really specific things about his toothbrushing.',
                    'I try to count how many positive things I can tell her. ',
                ],
                tipsEs: [
                    'Pienso en iluminar los comportamientos que quiero desarrollar, como si fueran una planta.',
                    'Convierto en un juego para mí el decir cosas realmente específicas sobre su cepillado de dientes.',
                    'Trato de contar cuántas cosas positivas puedo decirle. ',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '04_describe_child_doing.mp3',
            },
            {
                id: '04_reward_toothbrushing_effort',
                image: 'BR2S Phase II RNS Icons-17.png',
                text: 'Reward children’s toothbrushing effort.',
                textEs: 'Premiar a los niños por sus esfuerzos en cepillarse.',
                tips: [
                    'I let her choose some fun stickers for her toothbrush chart.',
                    'I draw a star on the calendar in the bathroom.',
                    'After 5 days, my son gets to choose a special show.',
                ],
                tipsEs: [
                    'Le dejé elegir algunas pegatinas divertidas para su tabla de cepillos de dientes.',
                    'Dibujo una estrella en el calendario del baño.',
                    'Después de 5 días, mi hijo elige un programa especial.',
                ],

                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '04_reward_toothbrushing_effort.mp3',
            },
        ],
    },
    /* Module 5 */
    {
        levelNum: 5,
        steps: [
            {
                id: '05_gradually_dilute_dilute',
                image: 'BR2S Phase II RNS Icons-18.png',
                text: 'Gradually dilute bottles-to-bed or sippy cups to dilute-only.',
                textEs: 'Gradualmente aguar los biberones o vasitos de noche hasta que solo contengan agua.',
                tips: [
                    'I took it slow and little by little.',
                    'I made it so gradual that my daughter didn’t notice.',
                    'I put a chart on the fridge to remind us how much to dilute.',
                ],
                tipsEs: [
                    'Me lo tomé con calma y poco a poco.',
                    'Lo hice tan gradual que mi hija no se dio cuenta.',
                    'Puse una gráfica en la nevera para recordarnos cuánto diluir.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '05_gradually_dilute_dilute.mp3',
            },
            {
                id: '05_child_use_cups',
                image: 'BR2S Phase II RNS Icons-19.png',
                text: 'Aim to have my child use cups instead of bottles by age one.',
                textEs: 'Tratar de que mi hijo/a use vasos en lugar de biberones a la edad de un año.',
                tips: [
                    'I put a really small amount of milk or water in his cup.',
                    'I decided to be ok with him spilling. That’s how they learn.',
                    'I kept lots of dish rags around for spills.',
                ],
                tipsEs: [
                    'Puse una cantidad muy pequeña de leche o agua en su taza.',
                    'Decidí estar bien con él derramando. Así es como aprenden.',
                    'Mantuve muchos trapos de cocina para derrames.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '05_child_use_cup.mp3',
            },
            {
                id: '05_add_crunchy_vegetables',
                image: 'BR2S Phase II RNS Icons-20.png',
                text: 'Add crunchy vegetables to older children’s snacks (carrots, cucumber, peppers, snap-peas).',
                textEs: 'Agregar verduras crujientes a las meriendas de los niños mayores (zanahorias, pepinos, pimientos, chícharos).',
                tips: [
                    'I arrange the veggies on a plate in the shape of a face.',
                    'I let my daughter choose a veggie at the store.',
                    'For some reason, how I cut the carrots makes a big difference to my son.',
                ],
                tipsEs: [
                    'Acomodo las verduras en un plato en forma de cara.',
                    'Dejé que mi hija escogiera una verdura en la tienda.',
                    'Por alguna razón, la forma en que corto las zanahorias hace una gran diferencia para mi hijo.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '05_add_crunchy_vegetables.mp3',
            },
            {
                id: '05_gradually_replace_soda',
                image: 'BR2S Phase II RNS Icons-21.png',
                text: 'Gradually replace soda and juice with water.',
                textEs: 'Sustituir gradualmente los refrescos y el jugo con agua.',
                tips: [
                    'I kept diluting the juice, little by little.',
                    'We have orange slices in our water now. They love that.',
                    'I tell him soda isn’t an everyday drink.',
                ],
                tipsEs: [
                    'Seguí diluyendo el jugo, poco a poco.',
                    'Tenemos rodajas de naranja en nuestra agua ahora. Les encanta eso.',
                    'Le digo que la gaseosa no es una bebida de todos los días.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '05_gradually_replace_soda.mp3',
            },
            {
                id: '05_reduce_added_sugar',
                image: 'BR2S Phase II RNS Icons-22.png',
                text: 'Aim to reduce added sugar each day.',
                textEs: 'Tratar de reducir el azúcar adicional todos los días.',
                tips: [
                    'I look at the labels to see how much added sugar there is.',
                    'I buy less packaged food.',
                    'I say, "The dentist says this is not an everyday food."',
                ],
                tipsEs: [
                    'Miro las etiquetas para ver cuánto azúcar añadido hay.',
                    'Compro menos comida envasada.',
                    'Digo: "El dentista dice que esto no es un alimento de todos los días".',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '05_reduce_added_sugar.mp3',
            },
        ],
    },
    /* Module 6 */
    {
        levelNum: 6,
        steps: [
            {
                id: '06_expecting_baby_brush',
                image: 'BR2S Phase II RNS Icons-23.png',
                text: 'If I’m expecting a baby, brush my teeth with fluoride toothpaste after breakfast and last food/drink of the day.',
                textEs: 'Si estoy esperando un bebé, cepillarme los dientes con pasta dental con flúor después del desayuno y la última comida / bebida del día.',
                tips: [
                    'I think about about how it helps my baby.',
                    'I let myself watch commedy clips while I floss and brush.',
                    'I started calling it--me time.',
                ],
                tipsEs: [
                    'Pienso en cómo ayuda a mi bebé',
                    'Me permito ver clips de comedia mientras uso hilo dental y me cepillo',
                    'Empecé a llamarlo... mi tiempo',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '06_expecting_baby_brush.mp3',
            },
            {
                id: '06_ask_child_healthcare',
                image: 'BR2S Phase II RNS Icons-24.png',
                text: 'Ask my child’s health care provider about fluoride drops, tablets, or rinses.',
                textEs: 'Pregúntarle al médico de mi hijo acerca de las gotas, tabletas o enjuagues de fluoruro.',
                tips: [
                    'I didn’t wait until the appointment, I just called the office.',
                    'I keep a list on my phone of things I want to ask his doctor or dentist, so I don’t forget.',
                    'I talked to my son’s doctor and dentist.',
                ],
                tipsEs: [
                    'No esperé hasta la cita, solo llamé a la oficina.',
                    'Mantengo una lista en mi teléfono de cosas que quiero preguntarle a su médico o dentista, para que no se me olvide.',
                    'Hablé con el médico y el dentista de mi hijo.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '06_ask_child_healthcare.mp3',
            },
            {
                id: '06_fluoride_varnishes_sealants',
                image: 'BR2S Phase II RNS Icons-25.png',
                text: 'Opt-IN to fluoride varnishes and sealants offered at schools and health care centers.',
                textEs: 'Optar por los barnices y selladores con flúor que se ofrecen en las escuelas y los centros de salud.',
                tips: [
                    'I asked for the form at our Head Start.',
                    'I put it on my To Do list on my fridge.',
                    'I called my daughter’s Head Start to ask about it.',
                ],
                tipsEs: [
                    'Pedí el formulario en nuestro Head Start.',
                    'Lo puse en mi lista de tareas pendientes en mi refrigerador.',
                    'Llamé al Head Start de mi hija para preguntar al respecto.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '06_fluoride_varnishes_sealants.mp3',
            },
            {
                id: '06_regular_brushing_routine',
                image: 'BR2S Phase II RNS Icons-26.png',
                text: 'Once we have a regular brushing routine with fluoride, add daily flossing.',
                textEs: 'Una vez que tengamos una rutina de cepillado regular con flúor, agregar el hilo dental diario.',
                tips: [
                    'I told him he’s getting so big now, he gets to use flossers.',
                    'I let my kids choose fun flossers, like it’s a special treat.',
                    'I give my kids an extra sticker for practicing flossing.',
                ],
                tipsEs: [
                    'Le dije que ahora está tan grande que puede usar hilo dental',
                    'Dejo que mis hijos elijan hilos dentales divertidos, como si fuera un regalo especial',
                    'Le doy a mis hijos una calcomanía extra por practicar con el hilo dental.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '06_regular_brushing_routine.mp3',
            },
        ],
    },
    /* Module 7 */
    {
        levelNum: 7,
        steps: [
            {
                id: '07_ask_referrals_dental',
                image: 'BR2S Phase II RNS Icons-27.png',
                text: 'Ask for referrals for dental providers for my child by age one. ',
                textEs: 'Pedir recomendaciones de proveedores dentales para mi hijo/a antes de que cumpla un año.',
                tips: [
                    'I called my insurance and asked for refrerrals.',
                    'I called the Head Start office.',
                    'I talked to other parents at the park about it.',
                ],
                tipsEs: [
                    'Llamé a mi seguro y pedí referencias',
                    'Llamé a la oficina de Head Start',
                    'Hablé con otros padres en el parque al respecto',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '07_ask_referrals_dental.mp3',
            },
            {
                id: '07_dental_care_appointment',
                image: 'BR2S Phase II RNS Icons-28.png',
                text: 'Set-up dental care appointment for my child.',
                textEs: 'Programar una cita dental para mi hijo/a.',
                tips: [
                    'I put it on a To Do list on my fridge, as a reminder.',
                    'I called and said we were new patients and that I had some questions.',
                    'I decided to be ok with asking basic questions.',
                ],
                tipsEs: [
                    'Lo puse en una lista de tareas pendientes en mi nevera, como recordatorio.',
                    'Llamé y dije que éramos pacientes nuevos y que tenía algunas preguntas.',
                    'Decidí aceptar hacer preguntas básicas.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '07_dental_care_appointment.mp3',
            },
            {
                id: '07_think_care_appointments',
                image: 'BR2S Phase II RNS Icons-29.png',
                text: 'Think about what gets in the way of dental care appointments (transportation, calendar, time off work).',
                textEs: 'Notar las barreras en acceder citas dentales (transporte, calendario, tiempo libre del trabajo).',
                tips: [
                    'If I didn’t put it on my calendar right away, I would forget.',
                    'I had to remind myself that it’s ok to take time off for this.',
                    'It was hard to ask my boss for the time off. I had to practice to get comfortable.',
                ],
                tipsEs: [
                    'Si no lo pusiera en mi calendario de inmediato, lo olvidaría.',
                    'Tuve que recordarme a mí mismo que está bien tomarme un tiempo libre para esto',
                    'Fue difícil pedirle a mi jefe el tiempo libre. Tuve que practicar para sentirme cómodo.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '07_think_care_appointments.mp3',
            },
            {
                id: '07_school_dental_services',
                image: 'BR2S Phase II RNS Icons-30.png',
                text: 'Opt-IN to school-based dental services.',
                textEs: 'Inscribirse en los servicios dentales proveidos en la escuela.',
                tips: [
                    'I asked at my daughter’s Head Start.',
                    'I signed the paper right away and put it back in his backpack so it was done.',
                    'I called the school office to ask about it.',
                ],
                tipsEs: [
                    'Pregunté en Head Start de mi hija.',
                    'Firmé el papel de inmediato y lo devolví a su mochila para que estuviera listo.',
                    'Llamé a la oficina de la escuela para preguntar al respecto.',
                ],

                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '07_school_dental_services.mp3',
            },
            {
                id: '07_show_picture_books',
                image: 'BR2S Phase II RNS Icons-31.png',
                text: 'Show my child picture books and videos about dental care (Sesame Street, Daniel Tiger).',
                textEs: 'Mostrar a mi hijo/a libros ilustrados y videos sobre el cuidado dental (Barrio Sésamo, Daniel Tiger).',
                tips: [
                    'I searched for--Daniel Tiger at dentist.',
                    'I asked the librarian for some kids’ books on going to the dentist.',
                    'We watched a bunch of different videos, so he knew all about it.',
                ],
                tipsEs: [
                    'Busqué... Daniel Tiger en el dentista.',
                    'Le pedí a la bibliotecaria algunos libros para niños sobre ir al dentista.',
                    'Vimos un montón de videos diferentes, por lo que sabía todo al respecto.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '07_show_picture_books.mp3',
            },
            {
                id: '07_regularly_check_spots',
                image: 'BR2S Phase II RNS Icons-32.png',
                text: 'Regularly check my child’s teeth for white or brown spots.',
                textEs: 'Revisar regularmente los dientes de mi hijo/a en busca de manchas blancas o marrones.',
                tips: [
                    'I let my son check my teeth first and then I check his.',
                    'We get out the flashlight. That’s fun.',
                    'I like seeing how they’re growing.',
                ],
                tipsEs: [
                    'Dejo que mi hijo revise mis dientes primero y luego yo reviso los suyos.',
                    'Saquemos la linterna. Eso es gracioso.',
                    'Me gusta ver cómo crecen.',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '07_regularly_check_spots.mp3',
            },
            {
                id: '07_baby_dental_appointment',
                image: 'BR2S Phase II RNS Icons-33.png',
                text: 'As soon as I know I’m going to have a baby, make a dental care appointment for myself.',
                textEs: 'Tan pronto que sepa que voy a tener un bebé, programar una cita dental para mí.',
                tips: [
                    'I put it on my To Do list on my fridge until it gets done.',
                    'It feels good to know you can help your baby’s future dental health.',
                    'I remind myself that it’s important self care. ',
                ],
                tipsEs: [
                    'Lo puse en mi lista de tareas pendientes en mi refrigerador hasta que esté listo.',
                    'Se siente bien saber que puede ayudar a la futura salud dental de su bebé.',
                    'Me recuerdo a mí mismo que es importante el cuidado personal. ',
                ],
                meditationVideo: 'Brts Mindfulness - 14 Seconds.mp4',
                meditationAudio: '07_baby_dental_appointment.mp3',
            },
        ],
    },
]
