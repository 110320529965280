import { readonly, ref, Ref } from 'vue'

import {
    firestore,
    FirestoreWriteBatch,
    serverTimestamp,
} from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useResourcesElements from '@/composables/global/use-resources-elements'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs, shiftDocNums } from '@/utils/firestore'

import { Resource } from '@/models/courses/resource'

const debug = false

const resources: Ref<Resource[]> = ref([])

export default function () {
    const { activeCourse } = useCourses()
    const { viewer } = useViewer()

    async function fetchResources() {
        logger(debug)
        if (activeCourse.value === undefined) throw 'No active course'

        const resourcesSnapshot = await firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')
            .orderBy('num')
            .get()

        resources.value = getCollectionDocs<Resource>(resourcesSnapshot)
    }

    async function addResource() {
        logger(debug)
        if (activeCourse.value === undefined) throw 'No active course'
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const resourcesCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')

        const lastResourceSnapshot = await resourcesCollection
            .orderBy('num')
            .limitToLast(1)
            .get()

        let newResourceNum = 1
        if (!lastResourceSnapshot.empty) {
            // Add new resource after existing last resource
            newResourceNum = lastResourceSnapshot.docs[0].get('num') + 1
        }

        // Add new resource
        const newResourceDoc = resourcesCollection.doc()
        await newResourceDoc.set({
            title: `New Resource`,
            num: newResourceNum,
            updatedBy: viewer.value.id,
            createdAt: serverTimestamp(),
        })

        await fetchResources()
    }

    async function deleteResource(resource: Resource) {
        logger(debug, resource)
        if (activeCourse.value === undefined) throw 'No active course'

        const { batchUpdateElements } = useResourcesElements()

        const resourcesCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')
        const resourceDoc = resourcesCollection.doc(resource.id)

        const batch = firestore.batch()

        // Update affected resources' num fields
        await shiftDocNums(batch, resourcesCollection, {
            shift: -1,
            fromNum: resource.num,
        })

        // Delete elements
        batchUpdateElements(resource.id, [], [], batch)

        // Delete resource
        batch.delete(resourceDoc)

        await batch.commit()
        await fetchResources()
    }

    async function batchUpdateResource(
        resourceId: string,
        resourceFields: { title: string },
        batch: FirestoreWriteBatch
    ) {
        logger(debug, resourceId)
        if (activeCourse.value === undefined) throw 'No active course'
        if (viewer.value === undefined) throw 'Viewer not initialized'

        // Update resource
        batch.update(
            firestore
                .collection('courses')
                .doc(activeCourse.value.id)
                .collection('resources')
                .doc(resourceId),
            {
                ...resourceFields,
                updatedBy: viewer.value.id,
                updatedAt: serverTimestamp(),
            }
        )
    }

    async function reorderResources(
        reorderElements: (resources: Resource[]) => Resource[]
    ) {
        logger(debug, reorderElements)
        if (activeCourse.value === undefined) throw 'No active course'

        const resourcesCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')

        const batch = firestore.batch()

        resources.value = reorderElements(resources.value)
        resources.value.forEach((resource, index) => {
            const resourceDoc = resourcesCollection.doc(resource.id)
            batch.update(resourceDoc, { num: index + 1 })
            resource.num = index + 1
        })

        await batch.commit()
    }

    function deinitResources() {
        logger(debug)
        resources.value = []
    }

    return {
        resources: readonly(resources),

        addResource,
        batchUpdateResource,
        deinitResources,
        deleteResource,
        fetchResources,
        reorderResources,
    }
}
