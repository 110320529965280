/* eslint-disable @typescript-eslint/no-explicit-any */

import { logger } from '@/utils/debug'

const debug = false

/**
 * Shallow array comparison utility
 *
 * @param arr1 an array of comparison
 * @param arr2 an array of comparison
 * @returns true if arr1 and arr2 have shallow equality
 */
export function arrayCompare(arr1: any[], arr2: any[]) {
    logger(debug, arr1, arr2)

    if (arr1 === undefined && arr2 === undefined) return true
    if (arr1 === undefined || arr2 === undefined) return false

    const arr2sorted = arr2.slice().sort()
    return (
        arr1.length === arr2.length &&
        arr1
            .slice()
            .sort()
            .every((value, index) => value === arr2sorted[index])
    )
}
/**
 * Shallow equity comparison of objects utility
 * sort arrays before calling if desired
 *
 * @param arr1 an array of objects comparison
 * @param arr2 an array of objects comparison
 * @returns true if each arr1[i] object has equivalent keys and values to arr2[i]
 */
export function arrayCompareObjects(arr1: object[], arr2: object[]) {
    logger(debug, [arr1, arr2])

    if (arr1 === undefined && arr2 === undefined) return true
    if (arr1 === undefined || arr2 === undefined) return false

    //recursive function, checks if keys length is equal and if each key[value] is equal
    function objectsEqual(obj1: any, obj2: any): boolean {
        return typeof obj1 === 'object' && Object.keys(obj1).length > 0
            ? Object.keys(obj1).length === Object.keys(obj2).length &&
                  Object.keys(obj1).every((p) => objectsEqual(obj1[p], obj2[p]))
            : obj1 === obj2
    }

    return (
        arr1.length === arr2.length &&
        arr1.every((obj, index) => objectsEqual(obj, arr2[index]))
    )
}
/**
 * object array sorting by key utility
 *
 * @param arr an array of objects to be sorted
 * @param key the key to sort them by
 * @returns sorted array in ascending order
 */
export function sortObjectArray(arr: object[], key: string) {
    logger(debug, arr, key)

    if (arr === undefined || arr === null || !key.length) return arr

    if (
        arr.every((obj) => {
            return (
                key in obj && typeof obj[key as keyof typeof obj] === 'string'
            )
        })
    ) {
        return arr.sort((a, b) => {
            return a[key as keyof typeof a] > b[key as keyof typeof b]
                ? 1
                : b[key as keyof typeof b] > a[key as keyof typeof a]
                  ? -1
                  : 0
        })
    } else if (
        arr.every((obj) => {
            return (
                key in obj && typeof obj[key as keyof typeof obj] === 'number'
            )
        })
    ) {
        return arr.sort((a, b) => {
            return a[key as keyof typeof a] - b[key as keyof typeof b]
        })
    }
}

export function createShuffledArray<T>(array: T[]) {
    const shuffledArray = [...array]
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[shuffledArray[i], shuffledArray[j]] = [
            shuffledArray[j],
            shuffledArray[i],
        ]
    }
    return shuffledArray
}

/**
 * Gets the property value of path from all elements in collection
 *
 * @param set the collection to iterate over.
 * @param property the property name to pluck
 * @returns returns the property values.
 */
export function pluck<T>(set: T[], property: keyof T) {
    return set.map((r) => r[property])
}

export function deepCopy<T>(array: T[]): T[] {
    return array.map((item) => {
        if (Array.isArray(item)) {
            return deepCopy(item)
        } else if (item !== null && typeof item === 'object') {
            return { ...deepCopyObject(item) }
        } else {
            return item
        }
    })
}

function deepCopyObject(obj: any): any {
    const copy: any = {}
    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            copy[key] = deepCopy(obj[key])
        } else if (obj[key] !== null && typeof obj[key] === 'object') {
            copy[key] = { ...deepCopyObject(obj[key]) }
        } else {
            copy[key] = obj[key]
        }
    }
    return copy
}
