import { firestore } from 'firebase/app'

import { TreeNode } from '@/models/_utils/tree'
import { NudgeItem } from '@/models/courses/level'

export type User = {
    /** User email */
    id: string
    researchId?: string

    roles: UserRole[]
    status: UserStatus

    /** IDs of guides this user is linked to */
    guides?: string[]
    /** [todo] Number of users a guide can create - 0 default, up to ~1k? */
    userSlots?: number

    name: string
    displayMode?: DisplayMode
    displayName?: string
    timezone: Timezone
    location?: string
    phone?: string

    /** Map Course ID to an unlocked level # - 0 = all, -1 = none */
    courses?: UserCourses
    /** Course ID */
    activeCourse?: string

    lockedPage?: LockedPage

    plan?: Note
    /** Separate collection would be needed for note responses */
    notes?: {
        [num: number]: Note
    }

    /** Add an option to enable, remove to disable */
    options?: UserOption[]
    /** Map options that require a value property */
    properties?: UserProperties
    tags?: UserTag[]

    createdAt: firestore.Timestamp
    /** The first time user logged in */
    initialLoginAt: firestore.Timestamp
    /** Most recent login */
    loginAt?: firestore.Timestamp
    /** Most recent active */
    activeAt?: firestore.Timestamp
    /** Most recent updated */
    updatedAt?: firestore.Timestamp

    /** Health Profile for IDD */
    healthProfile?: {
        healthProfileForms?: HealthProfile
        topicsToCover?: string[]
        userOptions?: TreeNode[] // ID's tree in custom order configuration
    }

    /** User ID */
    createdBy: string
    /** User ID */
    updatedBy?: string

    currentCall?: CallData

    /** End of Level Nudges */
    endOfLevelNudge?: {
        nextNudgeToTrigger: {
            nudge: NudgeItem
            triggerTime: string
            metadata: { courseId: string; levelId: string }
        }
        /** Array of already triggered nudge IDs. Only current level nudges are stored */
        nudgesTriggered: {
            nudgeId: string
            triggerTime: string
        }[]
    }

    /** SibTime Moment */
    sibTimeMoment?: SibTimeMoment
}

type CallData = {
    roomId: string
    chatId: string
}
type LockedPage = {
    courseId: string
    levelId: string
    pageId: string
}

type Note = {
    title?: string
    text?: string
}

export type UserCourses = {
    [courseId: string]: number
}

/**
 * - guide: can manage and interact with assigned users
 * - editor: can create & edit course content
 * - manager: can create & edit non-admin users
 * - admin: can create & edit all users and content
 */
export const roleOptions = ['guide', 'editor', 'manager', 'admin'] as const
export type UserRole = (typeof roleOptions)[number]

export const displayModes = ['light', 'dark', 'auto'] as const
export type DisplayMode = (typeof displayModes)[number]

export type DisplayTheme = 'light' | 'dark'

export const timezoneOptions = [
    'eastern',
    'pacific',
    'central',
    'mountain',
    'atlantic',
] as const
export type Timezone = (typeof timezoneOptions)[number]

export const userOptions = ['autoplay', 'notifications'] as const
export type UserOption = (typeof userOptions)[number]

export type UserProperties = {
    agency?: string
    school?: string
    classrooms?: string[]
    autoreply?: string
    /** Page ID */
    lockedPage?: string
    recordBrushTime?: number
    /** Currently selected Reachable Next Step */
    currentStep?: string
    mentalHealthProvider?: string
    moodAnchorLow?: string
    moodAnchorHigh?: string
    onTheMoveAudioIndex?: number
    palsChildAverage?: number
    palsChildGoal?: number
    palsSelfAverage?: number
    palsSelfGoal?: number
    /** Interactive IDs */
    seenInteractives?: string[]
    startDate?: firestore.Timestamp
    stars?: number
    /** score given to users on creation with phq9 enabled */
    t1?: number
    unseenRewards?: boolean
}

export const statusOptions = [
    'new',
    'active',
    'inactive',
    'coach',
    'completed',
    'withdrawn',
    'waitingT2',
    'noT3',
    'staff',
    'practiceTest',
    'hsStaff',
] as const
export type UserStatus = (typeof statusOptions)[number]

export type UserGroup =
    | 'all'
    | 'users'
    | 'guests'
    | 'guides'
    | 'editors'
    | 'managers'
    | 'admins'

export const userTagOptions = ['non-study'] as const
export type UserTag = (typeof userTagOptions)[number]

export type HealthProfile = {
    culture?: HealthProfileCulture
    health?: HealthProfileHealth
    wellBeing?: HealthProfileWellBeing
    parenting?: HealthProfileParenting
}

export const healthProfileFormKeys = [
    'culture',
    'health',
    'wellBeing',
    'parenting',
] as const

export type HealthProfileFormTypes = (typeof healthProfileFormKeys)[number]

export const cultureResponseScores = [0, 1, 2, 3, 4, 5] as const
export type CultureResponseScoreType = (typeof cultureResponseScores)[number]
export type HealthProfileCulture = {
    createdAt: firestore.Timestamp

    /** 0-25 */
    totalScore: number
    /** Total Score is greater than or equal to 8 OR the response to Q5 has a score of 2, 3, 4, or 5 */
    redFlagScore?: boolean
    questions: CultureResponseScoreType[]
}

export type HealthResponseScore = {
    responseIndex: number
    rating: 0 | 1
}
export type HealthProfileHealth = {
    createdAt: firestore.Timestamp
    /** 0-8 */
    totalScore: number
    /** Total Score is greater than or equal to 1 */
    redFlagScore?: boolean
    questions: HealthResponseScore[]
}

export const wellBeingResponseScores = [0, 1, 2, 3] as const
export type WellBeingResponseScoreType =
    (typeof wellBeingResponseScores)[number]
export type HealthProfileWellBeing = {
    createdAt?: firestore.Timestamp

    /** 0-4 */
    totalScore: number
    /** If the sum of Q1 and Q2 is greater than or equal to 3 OR the sum of Q3 and Q4 is greater than or equal to 3, */
    redFlagScore?: boolean
    questions: WellBeingResponseScoreType[]
}

export type ParentingResponseScore = {
    responseIndex: number
    rating: 0 | 1
}
export type HealthProfileParenting = {
    createdAt: firestore.Timestamp

    /** 0-18 */
    totalScore: number
    /** Total Score is greater than or equal to 1 */
    redFlagScore?: boolean
    questions: ParentingResponseScore[]
}

export type SibTimeMoment = {
    previousMoments?: string[]
    triggerTime?: string
    mustTrigger?: boolean
    activeInstanceId?: string
}

export const SIBTIME_MOMENT_CHECK_INTERVAL = 15 as const
export const SIBTIME_MOMENT_MINUTE_OPTIONS = [0, 15, 30, 45]
