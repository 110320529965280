import { addDays, differenceInDays, isEqual, sub } from 'date-fns'
import { computed, ComputedRef, ref, Ref } from 'vue'

import {
    firestore,
    FirestoreTimestamp,
    firestoreTimestamp,
    serverTimestamp,
} from '@/firebase-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import { MoodRating, RatingDayOption } from '@/models/users/mood-rating'

export type MoodRatingLineBreak = {
    date: FirestoreTimestamp
}

const debug = false

const moodRatings: Ref<MoodRating[]> = ref([])

export default function () {
    const { viewer } = useViewer()

    const moodRatingsWithBreaks: ComputedRef<
        (MoodRating | MoodRatingLineBreak)[]
    > = computed(() =>
        moodRatings.value.flatMap((moodRating, i) => {
            if (i === moodRatings.value.length - 1) return [moodRating]
            const currentDate = moodRating.date.toDate()
            const nextDate = moodRatings.value[i + 1].date.toDate()
            if (differenceInDays(nextDate, currentDate) > 1) {
                // The next rating date is more than one day away, add line break
                const lineBreak = {
                    date: firestoreTimestamp.fromDate(addDays(currentDate, 1)),
                } as MoodRatingLineBreak
                return [moodRating, lineBreak]
            } else {
                return [moodRating]
            }
        })
    )

    const firstMoodDate = computed(() => {
        if (moodRatings.value.length === 0) {
            return undefined
        } else {
            return moodRatings.value[0].date.toDate()
        }
    })

    const lastMoodDate = computed(() => {
        if (moodRatings.value.length === 0) {
            return undefined
        } else {
            const moodRatingLastIndex = moodRatings.value.length - 1
            return moodRatings.value[moodRatingLastIndex].date.toDate()
        }
    })

    const todayMidnightUTC = computed(() => {
        const currentDate = new Date()
        return new Date(
            Date.UTC(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
            )
        )
    })

    const yesterdayMidnightUTC = computed(() => {
        const currentDate = new Date()
        const yesterday = sub(currentDate, { days: 1 })
        return new Date(
            Date.UTC(
                yesterday.getFullYear(),
                yesterday.getMonth(),
                yesterday.getDate()
            )
        )
    })

    const todayRating = computed(() => {
        return moodRatings.value.find((rating) =>
            isEqual(rating.date.toDate(), todayMidnightUTC.value)
        )
    })

    const yesterdayRating = computed(() => {
        return moodRatings.value.find((rating) =>
            isEqual(rating.date.toDate(), yesterdayMidnightUTC.value)
        )
    })

    const canAccessSelfEvents = computed(() => {
        const activeCourse = viewer.value?.activeCourse
        if (activeCourse === undefined) return false

        const advancement = viewer.value?.courses?.[activeCourse]
        if (advancement === undefined) return false

        return advancement === 0 || advancement > 1
    })

    const canAccessChildEvents = computed(() => {
        const activeCourse = viewer.value?.activeCourse
        if (activeCourse === undefined) return false

        const advancement = viewer.value?.courses?.[activeCourse]
        if (advancement === undefined) return false

        return advancement === 0 || advancement > 3
    })

    async function fetchRatings(userId?: string) {
        logger(debug, userId ?? viewer.value?.id)
        if (userId === undefined && viewer.value?.id === undefined) {
            throw 'Cannot fetch ratings for undefined'
        }

        const ratingsSnapshot = await firestore
            .collection('users')
            .doc(userId ?? viewer.value?.id)
            .collection('mood-ratings')
            .orderBy('date')
            .get()

        moodRatings.value = getCollectionDocs<MoodRating>(ratingsSnapshot)
    }

    async function fetchUserLastMoodRating(userId: string) {
        logger(debug, userId)

        const ratingsSnapshot = await firestore
            .collection('users')
            .doc(userId)
            .collection('mood-ratings')
            .orderBy('createdAt')
            .limitToLast(1)
            .get()

        if (ratingsSnapshot.empty) return undefined
        return ratingsSnapshot.docs[0]
    }

    function getRatingDate(day: RatingDayOption) {
        switch (day) {
            case 'today':
                return todayMidnightUTC.value
            case 'yesterday':
                return yesterdayMidnightUTC.value
            default:
                return undefined
        }
    }

    async function addRating(moodData: {
        day: RatingDayOption
        rating: number
        selfEvents: number
        childEvents: number
    }) {
        logger(debug, moodData)
        if (viewer.value === undefined) {
            throw 'Cannot add rating for undefined viewer'
        }

        const date = getRatingDate(moodData.day)
        if (date === undefined) {
            throw `Invalid rating date`
        }

        const { rating, selfEvents, childEvents } = moodData
        const existingRatingSnapshot = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('mood-ratings')
            .where('date', '==', date)
            .get()

        if (existingRatingSnapshot.empty) {
            const createdAt = serverTimestamp()
            return firestore
                .collection('users')
                .doc(viewer.value.id)
                .collection('mood-ratings')
                .add({ createdAt, date, rating, selfEvents, childEvents })
        } else {
            const updatedAt = serverTimestamp()
            const ref = existingRatingSnapshot.docs[0].ref
            return ref.set({ updatedAt, date, rating, selfEvents, childEvents })
        }
    }

    function deinitMoodRatings() {
        logger(debug)
        moodRatings.value = []
    }

    return {
        canAccessChildEvents,
        canAccessSelfEvents,
        firstMoodDate,
        lastMoodDate,
        moodRatingsWithBreaks,
        todayMidnightUTC,
        todayRating,
        yesterdayMidnightUTC,
        yesterdayRating,

        addRating,
        deinitMoodRatings,
        fetchRatings,
        fetchUserLastMoodRating,
    }
}
