import { readonly, ref } from 'vue'

import {
    firestore,
    FirestoreWriteBatch,
    serverTimestamp,
} from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useViewer from '@/composables/global/use-viewer'

import { UploadResult } from '@/composables/use-storage-upload'

import { logger } from '@/utils/debug'
import { generateDocId, getCollectionDocs } from '@/utils/firestore'

import { Element } from '@/models/courses/resources/element'

const debug = false

const elements = ref<{ [resourceId: string]: Element[] }>({})

export default function () {
    const { activeCourse } = useCourses()
    const { viewer } = useViewer()

    // CRUD

    async function fetchElements(resourceId: string) {
        logger(debug)

        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const elementsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')
            .doc(resourceId)
            .collection('elements')

        const elementsSnapshot = await elementsCollection.orderBy('num').get()
        elements.value[resourceId] =
            getCollectionDocs<Element>(elementsSnapshot)
    }

    async function batchSetElement(
        resourceId: string,
        element: Element,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, element)

        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const elementsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')
            .doc(resourceId)
            .collection('elements')

        batch.set(elementsCollection.doc(element.id), {
            ...element,
            updatedBy: viewer.value.id,
            updatedAt: serverTimestamp(),
        })
    }

    function batchDeleteElement(
        resourceId: string,
        element: Element,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, element)

        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const elementsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')
            .doc(resourceId)
            .collection('elements')

        const elementRef = elementsCollection.doc(element.id)

        batch.delete(elementRef)
    }

    function getElementsToDelete(
        resourceId: string,
        updatedElements: Element[]
    ) {
        logger(debug, updatedElements)

        return elements.value[resourceId].flatMap((cachedElement) => {
            const isDeleted = updatedElements.every(
                (updatedElement) => updatedElement.id !== cachedElement.id
            )

            return isDeleted ? [cachedElement] : []
        })
    }

    function batchUpdateElements(
        resourceId: string,
        updatedElements: Element[],
        uploadResults: (UploadResult | undefined)[],
        batch: FirestoreWriteBatch
    ) {
        logger(debug, updatedElements)

        updatedElements.forEach((updatedElement) => {
            const uploadResult = uploadResults?.find(
                (result) => result?.name === updatedElement.id
            )

            if (uploadResult !== undefined) {
                updatedElement.file = uploadResult.downloadUrl.toString()
            }

            batchSetElement(resourceId, updatedElement, batch)
        })

        const elementsToDelete = getElementsToDelete(
            resourceId,
            updatedElements
        )

        // Delete removed elements
        elementsToDelete.forEach((el) =>
            batchDeleteElement(resourceId, el, batch)
        )

        // Update cached elements
        elements.value[resourceId] = updatedElements

        // Sort updated cached elements
        elements.value[resourceId].sort((el1, el2) => el1.num - el2.num)
    }

    // Other

    function generateElementId(resourceId: string) {
        logger(debug)

        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const elementsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('resources')
            .doc(resourceId)
            .collection('elements')

        return generateDocId(elementsCollection)
    }

    function deinitResourcesElements() {
        logger(debug)
        elements.value = {}
    }

    return {
        elements: readonly(elements),

        batchUpdateElements,
        deinitResourcesElements,
        fetchElements,
        generateElementId,
    }
}
