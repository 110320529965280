import { toastController, ToastOptions } from '@ionic/vue'

export async function shortToast(message: string, options?: ToastOptions) {
    const toast = await toastController.create({
        message,
        duration: 2000,
        ...options,
    })
    return toast.present()
}

export async function longToast(message: string, options?: ToastOptions) {
    const toast = await toastController.create({
        message,
        duration: 3500,
        ...options,
    })
    return toast.present()
}
