import { firestore } from 'firebase/app'

import { Captionable } from '@/models/_utils/captionable'

export type FeedbackMedia<T = FeedbackId> = Captionable & {
    id: T

    file: string
    name: string

    createdAt: firestore.Timestamp
    updatedAt?: firestore.Timestamp
    /** User ID */
    updatedBy: string

    required?: boolean
    disabledScrubber?: boolean
}

export const feedbackCategories = ['correct', 'incorrect'] as const
export type FeedbackCategory = (typeof feedbackCategories)[number]

export const feedbackTypes = ['audio', 'video'] as const
export type FeedbackType = (typeof feedbackTypes)[number]

export type FeedbackId = `${FeedbackCategory}-${FeedbackType}`
export const feedbackIds = feedbackCategories.flatMap((category) =>
    feedbackTypes.map((type) => `${category}-${type}` as const)
)

export const feedbackMediaTypeUploadMap = {
    audio: 'feedbackAudio',
    video: 'feedbackVideo',
} as const

export const feedbackMediaTypeIconMap = {
    audio: 'musical-notes',
    video: 'film',
}
