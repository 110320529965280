import { compareDesc } from 'date-fns'
import { DeepReadonly, computed, ref, readonly } from 'vue'

import { firestore, deleteField, FirestoreTimestamp } from '@/firebase-config'

import useChats from '@/composables/global/use-chats'
import useUsers from '@/composables/global/use-users'
import useViewer from '@/composables/global/use-viewer'

import { listCallRecordings } from '@/utils/callable-cloud-functions'
import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'

import { CallRoom } from '@/models/chats/call-room'

const debug = false

export type CompositionData = {
    compositionId: string
    compositionStatus: 'enqueued' | 'processing' | 'completed'
    kind: 'audio' | 'video'
}

export type Composition = {
    id: string
    compositionData: CompositionData[]
    createdAt: FirestoreTimestamp
    createdBy: string // should be the one who created the room
    inProgress: boolean
    isRecording: boolean
    kind: 'audio' | 'video'
    missedCall?: boolean
    name: string
}
const { chatsArray } = useChats()
const { getUser } = useUsers()
const { viewer, isGuide } = useViewer()

const chatMemberId = ref<string>()
const chatId = ref<string>()
const duration = ref(0)
const interval = ref()
const localVideoTrackElement = ref<HTMLMediaElement>()
const remoteVideoTrackElement = ref<HTMLMediaElement>()
const screenSharingVideoTrackElement = ref<HTMLMediaElement>()
const callKind = ref('audio')

const hasRemoteTrack = ref()

const isCallPageOpenedByUser = ref<boolean>(false)

const isPictureInPicture = ref<boolean>()
const isCallAnswered = ref<boolean>()
const recordingRequest = ref(false)
const screenSharing = ref<{ by: string } | undefined>()

export default function () {
    const hasOngoingCallInChat = computed(
        () =>
            chatsArray.value.find((chat) => chat.id === chatId.value)
                ?.callInProgress && chatId.value
    )

    function clearDuration() {
        clearInterval(interval.value)
        duration.value = 0
    }

    /**
     *
     * Completes a room identified by its unique identifier (`roomSid`), stops recording any ongoing audio and video streams in the room and sends an automatic message to the chat.
     *
     * @param roomSid - The unique identifier of the video call room to complete.
     *  @param chatId - The unique identifier of the chat to which the automatic message will be sent.
     *
     */
    async function completeCallRoom(
        roomSid: string,
        chatId: string,
        duration = -1
    ) {
        logger(debug, roomSid, chatId)
        const callData: {
            inProgress: boolean
            isRecording: boolean
            finishedBy?: string
            duration?: number
        } = {
            inProgress: false,
            isRecording: false,
            finishedBy: viewer.value?.id,
        }
        if (duration !== -1) callData.duration = duration //Duration cannot be inferred on page reload.

        await firestore
            .collection('chats')
            .doc(chatId)
            .collection('call-room')
            .doc(roomSid)
            .update(callData)
    }

    function deinitCall() {
        logger(debug)

        chatId.value = undefined
        chatMemberId.value = undefined
        duration.value = 0
        localVideoTrackElement.value = undefined
        remoteVideoTrackElement.value = undefined
        hasRemoteTrack.value = undefined
        callKind.value = 'audio'
        recordingRequest.value = false
        isPictureInPicture.value = false
        screenSharing.value = undefined
        screenSharingVideoTrackElement.value = undefined
        isCallAnswered.value = false
        clearInterval(interval.value)
        isCallPageOpenedByUser.value = false
    }

    function deinitPictureInPicture() {
        logger(debug)
        localVideoTrackElement.value = undefined
        remoteVideoTrackElement.value = undefined
        screenSharingVideoTrackElement.value = undefined
        isPictureInPicture.value = false
    }

    /**
     * Handles the disconnection of the user from the call when the page is refreshed.
     * If the user is the only participant in the call or is a guide, it will complete (terminate) the call room.
     * This function is triggered when the page is refreshed to ensure proper call cleanup.
     */
    async function disconnectOnRefresh() {
        if (!viewer.value?.currentCall) return
        const {
            id: viewerId,
            currentCall: { chatId, roomId },
        } = viewer.value

        const call = await firestore
            .collection('chats')
            .doc(chatId)
            .collection('call-room')
            .doc(roomId)
            .get()
            .then(docData<CallRoom>)

        await firestore.collection('users').doc(viewerId).update({
            currentCall: deleteField(),
        })
        if (call.inProgress && (call.missedCall || isGuide.value)) {
            await completeCallRoom(roomId, chatId)
        }
    }

    function setIntervalDuration() {
        logger(debug)
        interval.value = setInterval(() => {
            duration.value++
        }, 1000)
    }
    async function setCallAnswered(newValue: boolean, roomSid: string) {
        isCallAnswered.value = true
        logger(debug, newValue)
        await firestore
            .collection('chats')
            .doc(chatId.value)
            .collection('call-room')
            .doc(roomSid)
            .update({
                missedCall: !newValue,
            })
    }

    function setChatId(newValue: string) {
        logger(debug, newValue)
        chatId.value = newValue
    }

    function setChatMemberId(newValue: string) {
        logger(debug, newValue)
        chatMemberId.value = newValue
    }

    function setCallKind(newValue: 'audio' | 'video') {
        logger(debug, newValue)
        callKind.value = newValue
    }

    function setLocalVideo(newValue: HTMLMediaElement) {
        logger(debug, newValue)
        localVideoTrackElement.value = newValue
    }

    function setPictureInPicture(newValue: boolean) {
        logger(debug, newValue)
        isPictureInPicture.value = newValue
    }

    function setRecordingRequest(newValue: boolean) {
        logger(debug, newValue)
        recordingRequest.value = newValue
    }

    function setRemoteTrack(newValue: boolean) {
        logger(debug, newValue)
        hasRemoteTrack.value = newValue
    }

    function setRemoteVideo(newValue: HTMLMediaElement) {
        logger(debug, newValue)
        remoteVideoTrackElement.value = newValue
    }

    function setScreenSharingVideoElement(
        newValue: HTMLMediaElement | undefined
    ) {
        logger(debug, newValue)
        screenSharingVideoTrackElement.value = newValue
    }

    function setScreenSharing(newValue: { by: string } | undefined) {
        logger(debug, newValue)
        screenSharing.value = newValue
        if (newValue === undefined) {
            screenSharingVideoTrackElement.value = undefined
        }
    }

    function setIsCallPageOpenedByUser(newValue: boolean) {
        logger(debug, newValue)
        isCallPageOpenedByUser.value = newValue
    }

    function isCompleted(callRecording: DeepReadonly<Composition>) {
        return (
            callRecording.compositionData.length > 0 &&
            callRecording.compositionData?.every(
                (item) => item.compositionStatus === 'completed'
            )
        )
    }

    function isEnqueuedOrProcessing(callRecording: DeepReadonly<Composition>) {
        return (
            callRecording.compositionData.length > 0 &&
            callRecording.compositionData?.some(
                (item) =>
                    item.compositionStatus === 'enqueued' ||
                    item.compositionStatus === 'processing'
            )
        )
    }

    /**
     *
     * Retrieves a list of all video compositions for a chat room identified by its unique identifier (`chatId`).
     *
     * @param  chatId - The unique identifier of the chat room to retrieve video compositions for.
     * @returns The composition list in sorted by creation date in descending order.
     *
     */
    async function getAllCompositions(chatId: string) {
        const callRoomSnapshot = await firestore
            .collection('chats')
            .doc(chatId)
            .collection('call-room')
            .get()

        const callRoomDocs = getCollectionDocs<CallRoom>(callRoomSnapshot)
        const compositionList: Composition[] = await Promise.all(
            callRoomDocs.map(async (callRoom) => {
                const recordings = await listCallRecordings(callRoom.id)
                const compositionData = recordings.data as CompositionData[]
                const composition = {
                    ...{ compositionData },
                    ...callRoom,
                }
                return composition
            })
        )
        return compositionList.sort((a, b) =>
            compareDesc(a.createdAt.toDate(), b.createdAt.toDate())
        )
    }

    async function getCallRecordings(
        userId: string
    ): Promise<{ userName: string; compositions: Composition[] }[]> {
        const userChatsSnapshot = await firestore
            .collection('chats')
            .where('members', 'array-contains', userId)
            .get()

        if (userChatsSnapshot.empty) return []

        const callRecordingsPromises = userChatsSnapshot.docs.map(
            async (chatSnapshot) => {
                const chatMembers: string[] = chatSnapshot.get('members')
                const chatIsActive =
                    chatSnapshot.get('isActive') && chatMembers.length > 1
                if (!chatIsActive) return undefined

                const compositions = await getAllCompositions(chatSnapshot.id)
                if (compositions.length === 0) return undefined

                const otherChatMember = chatMembers.find(
                    (member) => member !== userId
                )
                if (!otherChatMember) return undefined

                const otherChatMemberData = getUser(otherChatMember)
                return {
                    userName:
                        otherChatMemberData?.displayName ||
                        otherChatMemberData?.name ||
                        otherChatMember,
                    compositions,
                }
            }
        )

        const callRecordingsResults = await Promise.all(callRecordingsPromises)

        const callRecordings: {
            userName: string
            compositions: Composition[]
        }[] = callRecordingsResults.filter(
            (
                recording
            ): recording is { userName: string; compositions: Composition[] } =>
                recording !== undefined
        )

        return callRecordings
    }

    return {
        hasOngoingCallInChat,
        callKind: readonly(callKind),
        chatId: readonly(chatId),
        chatMemberId: readonly(chatMemberId),
        duration: readonly(duration),
        hasRemoteTrack,
        localVideoTrackElement: readonly(localVideoTrackElement),
        isPictureInPicture: readonly(isPictureInPicture),
        screenSharing: readonly(screenSharing),
        screenSharingVideoTrackElement: readonly(
            screenSharingVideoTrackElement
        ),
        isCallAnswered: readonly(isCallAnswered),
        recordingRequest: readonly(recordingRequest),
        remoteVideoTrackElement: readonly(remoteVideoTrackElement),
        isCallPageOpenedByUser: readonly(isCallPageOpenedByUser),

        completeCallRoom,
        clearDuration,
        deinitCall,
        deinitPictureInPicture,
        disconnectOnRefresh,

        isCompleted,
        isEnqueuedOrProcessing,
        getAllCompositions,
        getCallRecordings,
        setCallAnswered,
        setChatId,
        setChatMemberId,
        setIntervalDuration,
        setLocalVideo,
        setCallKind,
        setPictureInPicture,
        setRecordingRequest,
        setRemoteTrack,
        setRemoteVideo,
        setScreenSharing,
        setScreenSharingVideoElement,
        setIsCallPageOpenedByUser,
    }
}
