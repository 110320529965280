import Color from 'color'
import { readonly, ref } from 'vue'

import useAdminSettings from '@/composables/global/use-admin-settings'

import { logger } from '@/utils/debug'

import { Theme } from '@/models/admin-settings'
import { DisplayTheme } from '@/models/user'

const debug = false

export const colorDescriptions: { [colorName: string]: string } = {
    text: "Base color for text, icons and more. \n\n - Strongly characterizes the app's appearance \n\n - Should have high contrast with all background colors (background, menu, card)",
    'header-text': 'Color of header text.',
    background:
        "Background color behind content on every page. \n\n - Strongly characterizes the app's appearance \n\n - Should be neutral as not to dominate foreground content",
    'alt-background':
        "Background color filling empty space around content. \n\n - Strongly characterizes the app's appearance \n\n - Recommended to be a shade of 'background' with lower contrast against 'text'",
    toolbar:
        "Background color for top toolbar on every page. \n\n - Strongly characterizes the app's appearance \n\n - Good option for a prominent brand color",
    'toolbar-text': 'Color of toolbar text and icons.',
    menu: "Background color for side menu and secondary toolbars. \n\n - Moderately characterizes the app's appearance \n\n - Neutral colors close to 'background' recommended",
    'menu-icon': 'Color of menu icons.',
    card: "Background color for cards like levels and resources. \n\n - Moderately characterizes the app's appearance \n\n - Particularly high contrast against 'text' recommended",
    'video-bars':
        'Background color for video player. \n\n - Appears as "bars" around video content when video aspect ratio does not match player aspect ratio',
    'video-player': 'Background color for custom video player.',
    badge: 'Accent color in completion badges.',
    'progress-bar':
        'The color of the progress bar appearing in the footer of level pages.',
    primary:
        "Accent color used for primary buttons and focused or selected elements. \n\n - Moderately characterizes the app's appearance \n\n - Vibrant color with high contrast against 'background' recommended",
    secondary:
        "Accent color used for secondary buttons like tooltips and text-to-speech. \n\n - Vibrant color with high contrast against 'background' recommended",
    tertiary:
        "Accent color used for tertiary buttons and chat bubbles. \n\n - Vibrant color with high contrast against 'background' recommended",
    quaternary:
        "Accent color used for quaternary UI elements. \n\n - Vibrant color with high contrast against 'background' recommended",
    success:
        'Color used to indicate successful completion. \n\n - Light greens highly recommended',
    warning:
        'Color used to indicate partial completion or caution. \n\n - Yellows or oranges highly recommended',
    danger: 'Color used to indicate destructive actions (like deleting something) or failure. \n\n - Reds highly recommended',
    dark: "High-contrast color used in miscellaneous areas of UI; e.g. borders, horizontal rules. \n\n - Should be neutral and have high contrast with 'background'",
    medium: "Mid-contrast color used in miscellaneous areas of UI; e.g. borders, specialized buttons. \n\n - Should be neutral and have mid contrast with 'background'",
    light: "Low-contrast color used in miscellaneous areas of UI; e.g. panel backgrounds. \n\n - Should be neutral and have low contrast with 'background'",
}

export const defaultThemes: { lightTheme: Theme; darkTheme: Theme } = {
    lightTheme: {
        text: '#000000',
        'header-text': '#000000',
        background: '#ffffff',
        'alt-background': '#f4f3f7',
        toolbar: '#f9f8fa',
        'toolbar-text': '#000000',
        menu: '#f9f8fa',
        'menu-icon': '#000000',
        card: '#ffffff',
        'video-bars': '#000000',
        'video-player': '#000000',
        badge: '#2dd36f',
        'progress-bar': '#3880ff',
        primary: '#3880ff',
        secondary: '#3dc2ff',
        tertiary: '#5260ff',
        quaternary: '#fff152',
        success: '#2dd36f',
        warning: '#ffc409',
        danger: '#eb445a',
        dark: '#222428',
        medium: '#92949c',
        light: '#f4f5f8',
    },
    darkTheme: {
        text: '#ffffff',
        'header-text': '#ffffff',
        background: '#171717',
        'alt-background': '#090909',
        toolbar: '#131313',
        'toolbar-text': '#ffffff',
        menu: '#131313',
        'menu-icon': '#ffffff',
        card: '#1c1c1d',
        'video-bars': '#000000',
        'video-player': '#000000',
        badge: '#2fdf75',
        'progress-bar': '#428cff',
        primary: '#428cff',
        secondary: '#50c8ff',
        tertiary: '#6a64ff',
        quaternary: '#f9ff64',
        success: '#2fdf75',
        warning: '#ffd534',
        danger: '#ff4961',
        dark: '#f4f5f8',
        medium: '#989aa2',
        light: '#222428',
    },
}

/**
 * Returns an object containing 19 CSS variables corresponding to ion color steps 50-950.
 * For more info:
 * https://ionicframework.com/docs/theming/themes#stepped-color-generator
 */
function getSteppedColors(
    backgroundStr: string | Color,
    textStr: string | Color
): Theme {
    const backgroundColor = Color(backgroundStr)
    const textColor = Color(textStr)

    const steppedColors: Theme = {}

    for (let i = 1; i <= 19; i++) {
        const steppedColor = backgroundColor.mix(textColor, (i * 5) / 100).hex()
        steppedColors[`--ion-color-step-${i * 50}`] = steppedColor
    }

    return steppedColors
}

function getContrastTextColor(
    colorStr: string,
    textStr: string,
    bgStr: string
) {
    logger(debug, colorStr)
    const color = Color(colorStr)
    const textColor = Color(textStr)
    const bgColor = Color(bgStr)
    const lightColor = Color('#ffffff')
    const darkColor = Color('#222428')

    const textContrast = textColor.contrast(color)
    const bgContrast = bgColor.contrast(color)
    const lightContrast = lightColor.contrast(color)

    const MIN_CONTRAST = 4.5

    if (bgContrast > MIN_CONTRAST) return bgColor.hex()
    else if (textContrast > MIN_CONTRAST) return textColor.hex()
    else if (lightContrast > MIN_CONTRAST) return lightColor.hex()
    else return darkColor.hex()
}

function getRgbString(colorStr: string | Color) {
    logger(debug, colorStr)
    const color = Color(colorStr)
    return color.rgb().array().join(', ')
}

function getColorInIonicFormat(
    colorName: string,
    colorValue: string,
    textStr: string,
    bgStr: string
) {
    logger(debug, colorName, colorValue)
    const shadeRatio = 0.12
    const tintRatio = 0.1

    colorValue = colorValue.toLowerCase()

    const colorRbg = getRgbString(colorValue)
    const contrastText = getContrastTextColor(colorValue, textStr, bgStr)
    const contrastTextRgb = getRgbString(contrastText)
    const shade = Color(colorValue).mix(Color('#000'), shadeRatio)
    const tint = Color(colorValue).mix(Color('#fff'), tintRatio)

    return {
        [`--ion-color-${colorName}`]: colorValue,
        [`--ion-color-${colorName}-rgb`]: colorRbg,
        [`--ion-color-${colorName}-contrast`]: contrastText,
        [`--ion-color-${colorName}-contrast-rgb`]: contrastTextRgb,
        [`--ion-color-${colorName}-shade`]: shade.hex(),
        [`--ion-color-${colorName}-tint`]: tint.hex(),
    }
}

function getThemeInIonicFormat(mode: DisplayTheme, colors?: Theme) {
    logger(debug, mode, colors)

    // Use Ionic defaults for any unset theme colors
    const defaultTheme =
        mode === 'light' ? defaultThemes.lightTheme : defaultThemes.darkTheme
    const theme = { ...defaultTheme, ...(colors ?? {}) }

    const text = theme.text.toLowerCase()
    const background = theme.background.toLowerCase()
    const backgroundAlt = theme['alt-background'].toLowerCase()
    const toolbar = theme.toolbar.toLowerCase()
    const menu = theme.menu.toLowerCase()
    const card = theme.card.toLowerCase()

    let ionicCssVariables: Theme = {
        // Background colors
        '--ion-background-color': background,
        '--ion-background-color-rgb': getRgbString(background),
        '--ion-alt-background-color': backgroundAlt,
        '--ion-alt-background-color-rgb': getRgbString(backgroundAlt),
        '--ion-toolbar-background': toolbar,
        '--ion-toolbar-background-rgb': getRgbString(toolbar),
        '--ion-menu-background': menu,
        '--ion-menu-background-rgb': getRgbString(menu),
        '--ion-card-background': card,
        '--ion-card-background-rgb': getRgbString(card),

        // Text colors
        '--ion-text-color': text,
        '--ion-text-color-rgb': getRgbString(text),
        ...getSteppedColors(background, text),
    }

    // Add all other colors
    for (const colorName in theme) {
        ionicCssVariables = {
            ...ionicCssVariables,
            ...getColorInIonicFormat(
                colorName,
                theme[colorName],
                text,
                background
            ),
        }
    }

    return ionicCssVariables
}

export function setThemeToDocumentCss({
    mode,
    theme,
}: {
    mode: DisplayTheme
    theme?: Theme
}) {
    logger(debug, mode, theme)

    const { adminSettings, contentMaxWidth } = useAdminSettings()

    let setTheme = theme
    if (setTheme === undefined) {
        setTheme =
            mode === 'dark'
                ? adminSettings.value?.darkTheme
                : adminSettings.value?.lightTheme
    }

    const cssTheme = getThemeInIonicFormat(mode, setTheme)

    defaultAltBackground.value = cssTheme['--ion-alt-background-color']
    setAltBackground()

    // Get style element if it exists, otherwise create and append it to HTML head
    let documentStyleEl = document.getElementById('custom-theme-vars')
    if (documentStyleEl === null) {
        documentStyleEl = document.createElement('style')
        documentStyleEl.setAttribute('id', 'custom-theme-vars')
        documentStyleEl.setAttribute('type', 'text/css')
        document.head.appendChild(documentStyleEl)
    }

    // Set CSS variables for theme
    documentStyleEl.textContent = ':root{'

    // Color theme variables
    for (const [key, value] of Object.entries(cssTheme)) {
        documentStyleEl.textContent += `${key}: ${value};`
    }

    // Content width
    documentStyleEl.textContent += `--content-max-width: ${contentMaxWidth.value}px;`

    documentStyleEl.textContent += '}'
}

export function setThemeToElement(mode: DisplayTheme, elementId: string) {
    logger(debug, mode, elementId)

    const { adminSettings: composableAdminSettings } = useAdminSettings()
    const setTheme =
        mode === 'dark'
            ? composableAdminSettings.value?.darkTheme
            : composableAdminSettings.value?.lightTheme

    const cssTheme = getThemeInIonicFormat(mode, setTheme)

    const targetElement = document.getElementById(elementId)
    if (!targetElement) return

    let styleElement = targetElement.querySelector(
        `#${elementId}-custom-theme-vars`
    )
    if (!styleElement) {
        styleElement = document.createElement('style')
        styleElement.setAttribute('id', `#${elementId}-custom-theme-vars`)
        styleElement.setAttribute('type', 'text/css')
        targetElement.appendChild(styleElement)
    }

    let cssText = `#${elementId} {`
    for (const [key, value] of Object.entries(cssTheme)) {
        cssText += `${key}: ${value};`
    }
    cssText += '}'

    styleElement.textContent = cssText
}

/**
 * Tracks the admin-setting/default alt-background-color so alt-background-color
 * can be reset after leaving a page that has a custom alt-background-color set
 */
const defaultAltBackground = ref<string>(
    defaultThemes.lightTheme['alt-background']
)

function setAltBackground(color?: string) {
    logger(debug, color)

    document.documentElement.style.setProperty(
        '--ion-alt-background-color',
        color ?? defaultAltBackground.value
    )
}

export default function useThemes() {
    return {
        defaultAltBackground: readonly(defaultAltBackground),

        setAltBackground,
    }
}
