import { computed, readonly, ref } from 'vue'

import { firestore } from '@/firebase-config'
import i18n from '@/i18n-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'

import { nextSteps, Step } from '@/data/next-steps'

import { NextSteps, NextStepSelection } from '@/models/users/next-steps'

const debug = false

type SelectionOption = {
    selection: NextStepSelection
    color: string
    icon: string
    label: string
}

export const notInterested: SelectionOption = {
    selection: 'notInterested',
    color: 'danger',
    icon: 'close',
    label: 'Not interested',
}

export const workingOn: SelectionOption = {
    selection: 'workingOn',
    color: 'primary',
    icon: 'thumbs-up',
    label: 'Interested',
}

export const doingNext: SelectionOption = {
    selection: 'doingNext',
    color: 'tertiary',
    icon: 'footsteps',
    label: 'My next step',
}

export const done: SelectionOption = {
    selection: 'done',
    color: 'success',
    icon: 'checkmark',
    label: 'I do this',
}

export const selectionOptions: SelectionOption[] = [
    done,
    workingOn,
    notInterested,
]

const nextStepSelections = ref<{ [id: string]: NextStepSelection }>({})

export default function (levelNum?: number) {
    const { viewer } = useViewer()

    const levelNextSteps = computed(() => {
        const locale = i18n.global.locale.value
        return nextSteps
            .find((level) => level.levelNum === levelNum)
            ?.steps.map((step) => ({
                ...step,
                text: locale === 'es' ? step.textEs : step.text,
                tips: locale === 'es' ? step.tipsEs : step.tips,
            }))
    })

    const currentStep = computed(() =>
        nextSteps
            .flatMap((stepLevel) => stepLevel.steps)
            .find((step) => step.id === viewer.value?.properties?.currentStep)
    )

    function deinitReachableNextSteps() {
        logger(debug)
        nextStepSelections.value = {}
    }

    async function fetchSelections() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const nextStepSnapshot = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('next-steps')
            .get()

        const nextStepDocs = getCollectionDocs<NextSteps>(nextStepSnapshot)

        nextStepDocs.forEach((levelNextSteps) => {
            Object.keys(levelNextSteps.selections).forEach((id) => {
                nextStepSelections.value[id] = levelNextSteps.selections[id]
            })
        })
    }

    async function isCompleted() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const levelStepsSnapshot = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('next-steps')
            .where('levelNum', '==', levelNum)
            .limit(1)
            .get()

        return !levelStepsSnapshot.empty
    }

    function setStepSelection(step: Step, selection: NextStepSelection) {
        logger(debug, step, selection)
        nextStepSelections.value[step.id] = selection
        logger(debug, 'nextStepSelections:', nextStepSelections.value)
    }

    async function addNextStepSelections(stepId?: string) {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        // Update the selected task to doingNext
        if (stepId !== undefined) nextStepSelections.value[stepId] = 'doingNext'

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('next-steps')
            .add({
                levelNum,
                selections: nextStepSelections.value,
            })
    }

    async function saveNextStepSelections() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const nextStepsSnapshots = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('next-steps')
            .get()

        const batch = firestore.batch()

        nextStepsSnapshots.docs.forEach((nextStepsSnapshot) => {
            const nextStepDoc = docData<NextSteps>(nextStepsSnapshot)
            const stepsForLevel = nextSteps.find(
                (levelSteps) => levelSteps.levelNum === nextStepDoc.levelNum
            )?.steps
            if (stepsForLevel === undefined) return
            const updatedSteps: { [id: string]: NextStepSelection } = {}
            stepsForLevel.forEach((step) => {
                updatedSteps[step.id] = nextStepSelections.value[step.id]
            })

            batch.update(nextStepsSnapshot.ref, { selections: updatedSteps })
        })

        return batch.commit()
    }

    return {
        currentStep,
        levelNextSteps,
        nextStepSelections: readonly(nextStepSelections),

        addNextStepSelections,
        deinitReachableNextSteps,
        isCompleted,
        fetchSelections,
        saveNextStepSelections,
        setStepSelection,
    }
}
