import { Ref, ref, readonly } from 'vue'

import { firestore } from '@/firebase-config'
import i18n from '@/i18n-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import { Reminder } from '@/models/users/reminder'
import { repeatIntervals, RepeatInterval } from '@/models/users/reminder'

const debug = false

const reminders: Ref<Reminder[]> = ref([])

export default function () {
    const { viewer } = useViewer()

    function getI18nRepeatInterval(repeatInterval: RepeatInterval) {
        logger(debug, repeatInterval)
        const repeatIntervalIdx = repeatIntervals.indexOf(repeatInterval)
        return i18n.global.t(`Reminders.repeatIntervals[${repeatIntervalIdx}]`)
    }

    async function fetchReminders() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const remindersSnapshot = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('reminders')
            .orderBy('isActive', 'desc')
            .orderBy('date', 'desc')
            .get()

        reminders.value = getCollectionDocs<Reminder>(remindersSnapshot)

        logger(debug, reminders.value)
    }

    async function deleteReminder(reminderId: string) {
        logger(debug, reminderId)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const reminderDoc = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('reminders')
            .doc(reminderId)

        const reminderSnapshot = await reminderDoc.get()

        if (!reminderSnapshot.exists) return
        return reminderDoc.delete()
    }

    async function addReminder(fields: {
        title: string
        text?: string
        date: string
        repeatInterval: RepeatInterval
    }) {
        logger(debug, fields)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('reminders')
            .add({
                ...fields,
                originalDate: fields.date,
                isActive: true,
            })

        await fetchReminders()
    }

    async function deactivateExpiredReminders() {
        logger(debug)
        if (viewer.value?.id === undefined || reminders.value.length == 0) {
            return
        }

        const remindersCollection = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('reminders')

        const currentTime = new Date().getTime()

        const expiredRemindersPromises: Promise<void>[] = []
        reminders.value.forEach(async (reminder) => {
            const triggerTime = new Date(reminder.date).getTime()
            if (triggerTime > currentTime || reminder.repeatInterval != 'never')
                return
            const reminderSnapshot = await remindersCollection
                .doc(reminder.id)
                .get()
            if (!reminderSnapshot.exists) return
            expiredRemindersPromises.push(
                remindersCollection.doc(reminder.id).update({
                    isActive: false,
                })
            )
        })

        return Promise.all(expiredRemindersPromises)
    }

    function deinitReminders() {
        logger(debug)
        reminders.value = []
    }

    return {
        reminders: readonly(reminders),

        addReminder,
        deactivateExpiredReminders,
        deinitReminders,
        deleteReminder,
        fetchReminders,
        getI18nRepeatInterval,
    }
}
