import { Device } from '@capacitor/device'
import { Keyboard } from '@capacitor/keyboard'
import { useDebounceFn, useThrottleFn } from '@vueuse/core'
import { computed, readonly, ref } from 'vue'

import { logger } from '@/utils/debug'

const debug = false

export const IonicBreakpointSm = 576
export const IonicBreakpointMd = 768
export const IonicBreakpointLg = 992
export const IonicBreakpointXL = 1200

export const IonicBreakpointSmPx = `${IonicBreakpointSm}px`
export const IonicBreakpointMdPx = `${IonicBreakpointMd}px`
export const IonicBreakpointLgPx = `${IonicBreakpointLg}px`
export const IonicBreakpointXLPx = `${IonicBreakpointXL}px`

const windowHeight = ref(window.innerHeight)
const windowWidth = ref(window.innerWidth)

const smUp = computed(() => windowWidth.value >= IonicBreakpointSm)
const mdUp = computed(() => windowWidth.value >= IonicBreakpointMd)
const lgUp = computed(() => windowWidth.value >= IonicBreakpointLg)
const xlUp = computed(() => windowWidth.value >= IonicBreakpointXL)

const smDown = computed(() => windowWidth.value < IonicBreakpointSm)
const mdDown = computed(() => windowWidth.value < IonicBreakpointMd)
const lgDown = computed(() => windowWidth.value < IonicBreakpointLg)
const xlDown = computed(() => windowWidth.value < IonicBreakpointXL)

const isLandscape = computed(() => windowWidth.value > windowHeight.value)
const isPortrait = computed(() => windowHeight.value >= windowWidth.value)

function handleResize() {
    const { innerHeight, innerWidth } = window
    logger(debug, innerWidth, innerHeight)

    // Don't consider TheShellSideMenu width (80px) part of the responsive width
    if (innerWidth < IonicBreakpointMd + 80) {
        // Mini side menu will be hidden
        windowWidth.value = innerWidth - 80

        // Set CSS var to account for responsive vertical margin hiding in LevelPage
        document.documentElement.style.setProperty(
            '--level-page-vertical-space',
            `0px`
        )
    } else {
        // Mini side menu will be shown
        windowWidth.value = innerWidth

        // Set CSS var to account for responsive vertical margin showing in LevelPage
        document.documentElement.style.setProperty(
            '--level-page-vertical-space',
            `32px`
        )
    }

    windowHeight.value = innerHeight
}

// Set workaround CSS var --100vh that stores value of window.innerHeight for iOS
function update100vh() {
    const { innerHeight } = window
    logger(debug, innerHeight)

    document.documentElement.style.setProperty('--100vh', `${innerHeight}px`)
}

// Throttle on desktop for window drag resize UX
const throttledUpdateSizeDesktop = useThrottleFn(handleResize, 100)

// Debounce on mobile to unify redundnant updates from resize events
const debouncedUpdateSizeMobile = useDebounceFn(() => {
    handleResize()
    update100vh()
}, 100)

async function setupListeners() {
    const { platform } = await Device.getInfo()
    const isMobile = ['android', 'ios'].includes(platform)

    if (isMobile) {
        debouncedUpdateSizeMobile()
        Keyboard.addListener('keyboardDidShow', debouncedUpdateSizeMobile)
        Keyboard.addListener('keyboardDidHide', debouncedUpdateSizeMobile)

        window.addEventListener('orientationchange', debouncedUpdateSizeMobile)
        window.addEventListener('resize', debouncedUpdateSizeMobile)
    } else {
        throttledUpdateSizeDesktop()
        window.addEventListener('resize', throttledUpdateSizeDesktop)
    }
}

setupListeners()

/**
 * Adds throttled resize event listener to the window, unless one already
 * exists. Exposes reactive breakpoint checks equivelant to Ionic CSS.
 */
export default function () {
    return {
        smUp,
        mdUp,
        lgUp,
        xlUp,
        smDown,
        mdDown,
        lgDown,
        xlDown,

        isLandscape,
        isPortrait,

        windowHeight: readonly(windowHeight),
        windowWidth: readonly(windowWidth),
    }
}
