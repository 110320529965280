type AppConfig = {
    projectId: string
    isProductionMode: boolean
    version: string
    capacitor: {
        appId: string
        appName: string
        ios: {
            scheme: string
        }
        android: {
            flavor: string
        }
    }
    firebase: {
        apiKey: string
        authDomain: string
        databaseURL: string
        projectId: string
        storageBucket: string
        messagingSenderId: string
        appId: string
    }
}

export default JSON.parse(
    process.env.VUE_APP_PROJECT_CONFIG as string
) as AppConfig
