import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'

import i18n from '@/i18n-config'

import useViewer from '@/composables/global/use-viewer'

import { longToast } from '@/utils/ion-toast'

const { isOnWeb } = useViewer()

async function checkAndRequestPermissionsForFileSystem() {
    let permStatus = await Filesystem.checkPermissions()
    if (permStatus.publicStorage === 'prompt') {
        permStatus = await Filesystem.requestPermissions()
    } else if (permStatus.publicStorage !== 'granted') {
        throw new Error(i18n.global.t('FileSystem.error'))
    }
}

// Helper function to convert Blob to Base64
export function blobToBase64({
    blob,
    keepDataUri,
}: {
    blob: Blob
    keepDataUri: boolean
}): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            const base64Data = reader.result as string
            const cleanBase64 = base64Data.split(',')[1]
            resolve(keepDataUri ? base64Data : cleanBase64)
        }
        reader.onerror = (error) => {
            reject(error)
        }
        reader.readAsDataURL(blob)
    })
}

// Function to save file using Filesystem API
async function downloadFileOnDesktop(
    fileName: string,
    data: string,
    isBase64 = true
) {
    await checkAndRequestPermissionsForFileSystem()
    await Filesystem.writeFile({
        path: fileName,
        data,
        directory: Directory.Data,
        encoding: isBase64 ? undefined : Encoding.UTF8,
        recursive: true,
    })
}

// Function to download file on the web
function downloadFileOnWeb(blob: Blob, fileName: string) {
    const href = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', href)
    link.setAttribute('download', fileName)
    link.click()
    URL.revokeObjectURL(href)
}

export async function handleFileDownload(
    blobOrData: Blob | string,
    fileName: string
) {
    try {
        const cleanName = fileName.replace(/\//g, '-')
        longToast(i18n.global.t('FileSystem.downloadingFile'))
        const isBlob = typeof blobOrData === 'object'

        if (!isOnWeb.value) {
            const data: string = isBlob
                ? await blobToBase64({ blob: blobOrData, keepDataUri: false })
                : blobOrData
            await downloadFileOnDesktop(cleanName, data, isBlob)
        } else {
            if (!isBlob) return
            downloadFileOnWeb(blobOrData, cleanName)
        }

        longToast(i18n.global.t('FileSystem.fileDownloaded'))
    } catch (error) {
        throw new Error(`${i18n.global.t('FileSystem.error')} : ${error}`)
    }
}

/**
 * Used to download a file from an internal URL from the server like a Firebase Storage URL or in assets. The file is downloaded on the project's download folder on the device
 */
export async function downloadFileFromInternalURL(file: {
    url: string
    name: string
    extension: string
}) {
    try {
        const { url, name, extension } = file

        const fileName = `${name}.${extension}`

        const response = await fetch(url)
        const blob = await response.blob()

        await handleFileDownload(blob, fileName)
    } catch (error) {
        throw new Error(`${i18n.global.t('FileSystem.error')} : ${error}`)
    }
}

/**
 * Used to download a file from an external URL like Twilio recordings or any other URL. The file is downloaded on the download folder of the device
 */
export async function downloadFileFromExternalURL(file: {
    url: string
    name: string
    extension: string
}) {
    const { url, name, extension } = file
    const fileName = `${name.replace(/\//g, '-')}.${extension}`

    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', fileName)
    link.click()
    URL.revokeObjectURL(url)
}

export async function downloadCSV(args: {
    content: unknown[]
    fileName: string
    headers: string[]
}) {
    const { content, fileName, headers } = args
    try {
        const { stringify } = await import('csv-stringify/browser/esm')
        const csvString = await new Promise<string>((resolve, reject) => {
            stringify(
                content,
                { header: true, columns: headers },
                (error, str) => {
                    if (error) reject(error)
                    else resolve(str)
                }
            )
        })
        // For desktop, there is no need to convert to Blob and then Base64
        const data = isOnWeb.value
            ? new Blob([csvString], { type: 'text/csv;charset=utf-8,' })
            : csvString

        await handleFileDownload(data, `${fileName}.csv`)
    } catch (error) {
        throw new Error(`${i18n.global.t('FileSystem.error')} : ${error}`)
    }
}

export async function urlToBase64(url: string) {
    try {
        const response = await fetch(url)
        const blob = await response.blob()

        return blobToBase64({ blob, keepDataUri: false })
    } catch (error) {
        throw new Error(`${i18n.global.t('FileSystem.error')} : ${error}`)
    }
}
