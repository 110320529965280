import { firestore } from 'firebase/app'

import { Language } from '@/models/course'

export type AdminSettings = {
    id: 'settings'

    title: string
    titleEs?: string

    description?: string
    descriptionEs?: string

    lightTheme?: Theme
    darkTheme?: Theme

    /** Map languages to logo files */
    logo?: Logo

    /** Add an option to enable, remove to disable */
    options?: AdminOption[]
    /** Map options that require a property value */
    properties?: AdminProperties
    helpLink: string

    updatedAt?: firestore.Timestamp
    /** User ID */
    updatedBy?: string
}

export const adminOptions = [
    'agencies',
    'autoplay',
    'callsOnChat',
    'changeStatusOnProgress',
    'customLogin',
    'defaultVideoPlayer',
    'entities',
    // 'firstNamesOnly',
    'notifyOnProgress',
    'nudgeAtEndOfLevel',
    'responsiveLevelList',
    'textToSpeech',
    // 'questionCategories',
    'healthProfileForms',
] as const

export type AdminOption = (typeof adminOptions)[number]

export const adminOptionDescriptions: { [option in AdminOption]: string } = {
    agencies:
        'Enables grouping of users by agency. User managers and guides will have access only to users in their assigned agency or agencies.\n\n You can manage agencies in the section below labeled "Agencies".',
    entities:
        'Enables the use of entities like School and Classrooms in the app. Managers and guides will have access only to users in their assigned entities.\n\n You can manage entities in the section below labeled "Entities".',
    autoplay:
        'When enabled, new users will have the "autoplay" setting on by default. This will make the first audio or video element on level pages start to play immediately*. This setting can be changed by the user at any time. \n\n *Autoplay will only function if the user has interacted with the UI (e.g. clicking the next button). More info [here](https://developer.mozilla.org/en-US/docs/Web/Media/Autoplay_guide).',
    callsOnChat:
        'Enables participants and guides to make and receive audio and video calls directly on the Chat Page',
    changeStatusOnProgress:
        'When enabled, users status will be set to "active" when they enter their first level, and set to "completed" when they reach the last page of the last level',
    customLogin:
        'When enabled, users will be able to log in using a custom login page. Otherwise, users will log in using the default login page.',
    defaultVideoPlayer:
        'When enabled, videos will play in the default browser or device video player. Otherwise, curriculum videos will play in a custom-designed video player that does not overlay video content and is consistent across all browsers and devices.',
    // firstNamesOnly: 'This feature is under development',
    notifyOnProgress:
        'When enabled, sends a notification to guides when their participants reaches the final page of a level',
    nudgeAtEndOfLevel:
        'When enabled, nudges the user after they end a level to encourage them to continue to the next level or reflect on their progress. The nudge will appear as a push notification in the app.',
    responsiveLevelList:
        'When enabled, the levels list will be displayed in a responsive grid. Otherwise, the levels list will be displayed in a single column.',
    textToSpeech:
        'Enables text-to-speech on level pages and resources. A speaker button appears next to text which when pressed activates the screen reader to speak the corresponding text.',
    // questionCategories: 'This feature is under development',
    healthProfileForms:
        'When enabled, guides will be able to submit the Health Profile Forms (IDD Advocates) and see the Health Profile Dashboard.',
}

/** Maps color names to either hex encoded strings or CSS color names */
export type Theme = {
    [colorName: string]: string
}

type AdminProperties = {
    eulaMarkdown?: string
    featureTitles?: FeatureTitles
    featureIcons?: FeatureIcons
    privacyMarkdown?: string
    /** Should not be less than IonicBreakpointMd */
    contentMaxWidth?: number
}

export const features = [
    'agencies',
    'chats',
    'classrooms',
    'conversationBank',
    'courses',
    'guides',
    'levels',
    'home',
    'plan',
    'reminders',
    'resources',
    'schools',
    'users',
    'videos',
] as const

export type Features = (typeof features)[number]

export const menuFeatures = [
    'chats',
    'conversationBank',
    'courses',
    'levels',
    'home',
    'plan',
    'reminders',
    'resources',
    'users',
    'videos',
] as const

export type MenuFeatures = (typeof menuFeatures)[number]

export type FeatureTitles = {
    [language in Language]?: { [feature in Features]?: string }
}

export type FeatureIcons = {
    [feature in MenuFeatures]?: IconMedia
}

export type IconMedia = {
    base64: string
    fileAlt?: string
    title?: string
}

export type LogoMedia = {
    file: string
    fileAlt?: string
    title?: string
}

export type Logo = {
    [language in Language]?: LogoMedia
}
