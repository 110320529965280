import DOMPurify from 'dompurify'

export function initDomPurify() {
    // Open external links in new tab
    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
        if (node.tagName === 'A') {
            node.setAttribute('target', '_blank')
            node.setAttribute('rel', 'noopener')
        }
    })
}
