import { Ref, ref, readonly, DeepReadonly } from 'vue'

import { firestore, FirestoreWriteBatch } from '@/firebase-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'
import { PartialBy } from '@/utils/types'

import { School } from '@/models/agencies/school'
import { Classroom } from '@/models/agencies/schools/classroom'
import { Agency } from '@/models/agency'

const debug = false

export type SchoolForm = PartialBy<
    School & { classrooms?: ClassroomForm[] },
    'id'
>
export type ClassroomForm = PartialBy<Classroom, 'id'>

export type AgencyForm = PartialBy<
    Agency & {
        schools?: SchoolForm[]
    },
    'id'
>

const agencies: Ref<
    (Agency & { schools?: (School & { classrooms?: Classroom[] })[] })[]
> = ref([])

const agenciesCollection = firestore.collection('agencies')

export default function () {
    const { isGuide, updateViewerEntity } = useViewer()

    function copyAgency(agency: DeepReadonly<AgencyForm>): AgencyForm {
        return {
            ...agency,
            schools: agency.schools?.map(copySchool),
        }
    }

    function copySchool(school: DeepReadonly<SchoolForm>): SchoolForm {
        return {
            ...school,
            classrooms: school.classrooms?.map(copyClassroom),
        }
    }

    function copyClassroom(
        classroom: DeepReadonly<ClassroomForm>
    ): ClassroomForm {
        return { ...classroom }
    }

    function deinitAgencies() {
        logger(debug)
        agencies.value = []
    }

    async function fetchAgencies() {
        logger(debug)

        const agenciesSnapshot = await agenciesCollection.get()
        const agenciesData = getCollectionDocs<Agency>(agenciesSnapshot)

        const agenciesWithSchools = await Promise.all(
            agenciesData.map(async (agency) => {
                const schoolsSnapshot = await agenciesCollection
                    .doc(agency.id)
                    .collection('schools')
                    .get()
                const schoolsData = getCollectionDocs<School>(schoolsSnapshot)
                const schoolsWithClassrooms = await Promise.all(
                    schoolsData.map(async (school) => {
                        const classroomsSnapshot = await agenciesCollection
                            .doc(agency.id)
                            .collection('schools')
                            .doc(school.id)
                            .collection('classrooms')
                            .get()
                        const classroomsData =
                            getCollectionDocs<Classroom>(classroomsSnapshot)
                        return { ...school, classrooms: classroomsData }
                    })
                )
                return { ...agency, schools: schoolsWithClassrooms }
            })
        )
        agencies.value = agenciesWithSchools.sort((a, b) => {
            return a.title.localeCompare(b.title)
        })
    }

    async function fetchAgencyTitle(agencyId: string | undefined) {
        logger(debug, agencyId)

        if (agencyId === undefined) return 'N/A'
        const agency = await firestore
            .collection('agencies')
            .doc(agencyId)
            .get()
            .then(docData<Agency>)
        if (!agency) return 'N/A'
        return agency.title
    }

    async function updateAgencies(updatedAgencies: AgencyForm[]) {
        logger(debug, updatedAgencies)

        const batch = firestore.batch()

        await Promise.all(
            updatedAgencies.map((agency) => {
                return batchUpdateAgency(batch, agency)
            })
        )

        await batch.commit()
        await fetchAgencies()
    }

    async function batchUpdateAgency(
        batch: FirestoreWriteBatch,
        agency: AgencyForm
    ) {
        let agencyId = agency.id

        if (agencyId === undefined) {
            const newAgencyDoc = agenciesCollection.doc()
            agencyId = newAgencyDoc.id
            batch.set(newAgencyDoc, {
                title: agency.title,
                id: agencyId,
            })
        } else {
            const existingAgencyDoc = agenciesCollection.doc(agencyId)
            // Update if there was a change in the title
            agencies.value.forEach((existingAgency) => {
                if (
                    existingAgency.id === agencyId &&
                    existingAgency.title !== agency.title
                ) {
                    batch.update(existingAgencyDoc, { title: agency.title })
                    return
                }
            })
        }

        if (agency.schools) {
            return Promise.all(
                agency.schools.map((school) => {
                    if (agencyId)
                        return batchUpdateSchool(batch, agencyId, school)
                })
            )
        }
    }

    async function batchUpdateSchool(
        batch: FirestoreWriteBatch,
        agencyId: string,
        school: SchoolForm
    ) {
        let schoolId = school.id

        if (schoolId === undefined) {
            const newSchoolDoc = agenciesCollection
                .doc(agencyId)
                .collection('schools')
                .doc()
            schoolId = newSchoolDoc.id
            batch.set(newSchoolDoc, {
                title: school.title,
                id: schoolId,
            })
        } else {
            const existingSchoolDoc = agenciesCollection
                .doc(agencyId)
                .collection('schools')
                .doc(schoolId)

            // Update if there was a change in the title
            agencies.value.forEach((existingAgency) => {
                existingAgency.schools?.forEach((existingSchool) => {
                    if (
                        existingSchool.id === schoolId &&
                        existingSchool.title !== school.title
                    ) {
                        batch.update(existingSchoolDoc, { title: school.title })
                        return
                    }
                })
            })
        }

        if (school.classrooms) {
            await Promise.all(
                school.classrooms.map((classroom) => {
                    if (schoolId)
                        return batchUpdateClassroom(
                            batch,
                            agencyId,
                            schoolId,
                            classroom
                        )
                })
            )
        }
    }

    async function batchUpdateClassroom(
        batch: FirestoreWriteBatch,
        agencyId: string,
        schoolId: string,
        classroom: ClassroomForm
    ) {
        let classroomId = classroom.id

        if (classroomId === undefined) {
            const newClassroomDoc = agenciesCollection
                .doc(agencyId)
                .collection('schools')
                .doc(schoolId)
                .collection('classrooms')
                .doc()
            classroomId = newClassroomDoc.id
            batch.set(newClassroomDoc, {
                title: classroom.title,
                id: classroomId,
            })

            // Add the new classroom to the teacher's properties
            if (isGuide.value) {
                await updateViewerEntity('classrooms', classroomId)
            }
        } else {
            const existingClassroomDoc = agenciesCollection
                .doc(agencyId)
                .collection('schools')
                .doc(schoolId)
                .collection('classrooms')
                .doc(classroomId)

            batch.update(existingClassroomDoc, { title: classroom.title })
        }
    }

    return {
        agencies: readonly(agencies),

        copyAgency,
        copyClassroom,
        copySchool,
        deinitAgencies,
        fetchAgencies,
        fetchAgencyTitle,
        updateAgencies,
    }
}
