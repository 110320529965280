import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import { enUS, es } from 'date-fns/locale'
import parseISO from 'date-fns/parseISO'

import { loggerError } from '@/utils/debug'

import { Timezone } from '@/models/user'

type IanaTimezone =
    | 'America/Detroit'
    | 'America/Chicago'
    | 'America/Denver'
    | 'America/Puerto_Rico'
    | 'America/Los_Angeles'

export type LocaleCode = 'en' | 'es'

export const datetimeFormat = "MMM dd',' yyyy p"
export const datetimeFormatEs = "eee',' dd MMM',' yyyy hh:mm aaaa"

/**
 * Wrapper util for date-fns format function.
 * @param date
 * @param dateFormat see accepted patterns: https://date-fns.org/v2.29.1/docs/format
 * @param locale
 * @returns formatted date string, or empty string if the input date is empty or undefined
 */
export function formatDate(
    date: string | number | Date | undefined,
    dateFormat: string,
    localeCode?: LocaleCode
) {
    if (date === '' || date === undefined) return ''
    else
        return format(parseISO(new Date(date).toISOString()), dateFormat, {
            locale: localeCode === 'es' ? es : enUS,
        })
}

/**
 * Converts the given area time zone name to the corresponding IANA time zone name (America/Detroit, America/Chicago, America/Denver, America/Puerto_Rico or America/Los_Angeles).
 */
export function areaToIanaTimezone(areaTimezone: Timezone) {
    switch (areaTimezone) {
        case 'eastern':
            return 'America/Detroit'
        case 'central':
            return 'America/Chicago'
        case 'mountain':
            return 'America/Denver'
        case 'atlantic':
            return 'America/Puerto_Rico'
        default:
            return 'America/Los_Angeles'
    }
}

/**
 * Converts the given IANA time zone name to the corresponding area time zone name (eastern, central, mountain,atlantic or pacific)
 */
export function IanaToAreaTimezone(ianaTimezone: IanaTimezone) {
    switch (ianaTimezone) {
        case 'America/Detroit':
            return 'eastern'
        case 'America/Chicago':
            return 'central'
        case 'America/Denver':
            return 'mountain'
        case 'America/Puerto_Rico':
            return 'atlantic'
        default:
            return 'pacific'
    }
}
/**
 * Converts a time string in "hh:mm aaa" format to an ISO 8601 datetime string.
 * The returned datetime uses the current year, month, and day.
 */
export function convertTimeToISO8601(timeString: string): string | undefined {
    try {
        const match = timeString.match(/(\d+):(\d+)\s*(am|pm)/i)

        if (!match) return undefined

        const [, hours, minutes, meridian] = match

        let hoursInt = parseInt(hours, 10)
        if (meridian.toLowerCase() === 'pm' && hoursInt < 12) {
            hoursInt += 12
        } else if (meridian.toLowerCase() === 'am' && hoursInt === 12) {
            hoursInt = 0
        }

        const currentDate = new Date()
        currentDate.setHours(hoursInt, parseInt(minutes, 10), 0, 0)
        const isoDate = formatISO(currentDate)
        return isoDate
    } catch (error) {
        loggerError('Failed to convert time:', error)
        return undefined
    }
}
