import { Preferences } from '@capacitor/preferences'
import sizeof from 'firestore-size'
import { Ref, ref, readonly, computed } from 'vue'

import appConfig from '@/app-config'
import { firestore, serverTimestamp } from '@/firebase-config'
import i18n from '@/i18n-config'

import useDisplayMode from '@/composables/global/use-display-mode'
import { IonicBreakpointMd } from '@/composables/global/use-responsiveness'
import { setThemeToDocumentCss } from '@/composables/global/use-themes'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { cleanObjForFirestore, docData } from '@/utils/firestore'
import { objectFromEntries } from '@/utils/objects'

import { AdminSettings, adminOptions } from '@/models/admin-settings'

const debug = false

const ADMIN_SETTINGS_KEY = `${appConfig.projectId}-admin-settings`

const adminSettings: Ref<AdminSettings | undefined> = ref()
const adminSettingsDocumentSize: Ref<number | undefined> = ref()

const { locale } = i18n.global

/**
 * Computed property that returns an object with the admin options and their enabled status.
 */
const adminEnabled = computed(() => {
    const arr = adminOptions.map((option) => {
        return [
            option,
            adminSettings.value?.options?.includes(option) ?? false,
        ] as const
    })
    return objectFromEntries(arr)
})

/**
 * Computed property that returns the maximum width of the content area.
 * If the `contentMaxWidth` property is not set in the admin settings, it defaults to `IonicBreakpointMd`.
 */
const contentMaxWidth = computed(() => {
    return adminSettings.value?.properties?.contentMaxWidth ?? IonicBreakpointMd
})

const featureTitles = computed(() => {
    return adminSettings.value?.properties?.featureTitles?.[locale.value] ?? {}
})

const featureIcons = computed(() => {
    return adminSettings.value?.properties?.featureIcons ?? {}
})

export default function () {
    async function fetchAdminSettings() {
        logger(debug)

        const adminSettingsSnapshot = await firestore
            .collection('admin-settings')
            .doc('settings')
            .get()
        adminSettings.value = docData<AdminSettings>(adminSettingsSnapshot)
        adminSettingsDocumentSize.value = sizeof(adminSettings.value)

        await Preferences.set({
            key: ADMIN_SETTINGS_KEY,
            value: JSON.stringify(adminSettings.value),
        })
    }

    /**
     * Initializes the admin settings by retrieving them from local storage (fast).
     * If the admin settings are already stored in the storage, it parses the value and assigns it to `adminSettings`.
     * Finally, it fetches the admin settings.
     */
    async function initAdminSettings() {
        logger(debug)

        const { displayTheme } = useDisplayMode()
        const { value } = await Preferences.get({ key: ADMIN_SETTINGS_KEY })

        if (value) {
            // Do not block page render, use cached admin settings but fetch+update in background
            adminSettings.value = JSON.parse(value) as AdminSettings
            fetchAdminSettings().then(() =>
                setThemeToDocumentCss({ mode: displayTheme.value })
            )
        } else {
            // No cached admin settings, block page render until admin settings are fetched
            await fetchAdminSettings()
            setThemeToDocumentCss({ mode: displayTheme.value })
        }
    }

    async function updateAdminSettings(
        updatedSettings: Omit<AdminSettings, 'id'>
    ) {
        logger(debug, updatedSettings)

        const { viewer } = useViewer()

        if (viewer.value === undefined) throw 'Viewer not initialized'

        await firestore
            .collection('admin-settings')
            .doc('settings')
            .set({
                ...cleanObjForFirestore('set', updatedSettings),
                updatedAt: serverTimestamp(),
                updatedBy: viewer.value.id,
            })

        await fetchAdminSettings()
    }

    return {
        adminEnabled,
        adminSettings: readonly(adminSettings),
        adminSettingsDocumentSize: readonly(adminSettingsDocumentSize),
        contentMaxWidth,
        featureTitles,
        featureIcons,

        initAdminSettings,
        fetchAdminSettings,
        updateAdminSettings,
    }
}
