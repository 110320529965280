export type Reminder = {
    /** Generated by Firestore */
    id: string
    title: string
    text: string
    /** Date of next trigger, in ISO-8601 string format */
    date: string
    /** Start date */
    originalDate: string
    repeatInterval: RepeatInterval
    isActive: boolean
}

export const repeatIntervals = [
    'never',
    'day',
    'week',
    'two weeks',
    'month',
] as const
export type RepeatInterval = (typeof repeatIntervals)[number]

/** In minutes, cron-job run frequency */
export const taskInterval = 10 as const
