import { DeepReadonly, computed, readonly, ref } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useLevelPage from '@/composables/global/use-level-page'
import useQuiz, { QuizItem, ResponseState } from '@/composables/global/use-quiz'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { docData } from '@/utils/firestore'

import { Page } from '@/models/courses/levels/page'
import { LevelReviewAnswer } from '@/models/users/level-review-answer'

const debug = false

type LevelReviewQuizItem = QuizItem & {
    pageId: string
}

const levelReviewQuizItems = ref<LevelReviewQuizItem[]>([])
const parentQuizItems = ref<LevelReviewQuizItem[]>([])

const branchRootPages = ref<Page[]>([])

const levelReviewQuizScore = computed(() =>
    levelReviewQuizItems.value.reduce(
        (total, { answer, question }) =>
            answer?.isCorrect ||
            (question.type === 'text' &&
                answer?.text !== undefined &&
                answer.text !== '')
                ? total + 1
                : total,
        0
    )
)

// Percentage of correctly answered questions between parent and review quizzes.
const overallCorrectPercent = computed(() => {
    const totalParentQuestions = parentQuizItems.value.length
    if (totalParentQuestions === 0) return 0

    const totalCorrectParentQuestions =
        totalParentQuestions - levelReviewQuizItems.value.length
    const totalCorrectScore =
        totalCorrectParentQuestions + levelReviewQuizScore.value
    return Math.floor((totalCorrectScore / totalParentQuestions) * 100)
})

export default function () {
    const { fetchPreviousRootPages, page, setLevel } = useLevelPage()
    const {
        fetchAnswer,
        fetchChoices,
        fetchScorableQuestions,
        isResponseCorrect,
        setQuestionCollection,
    } = useQuiz()
    const { viewer } = useViewer()

    const isPassingScoreMet = computed(() => {
        if (!page.value?.levelReviewQuizEnabled) return undefined
        if (!page.value.levelReviewQuizPassingScore) return undefined

        return (
            overallCorrectPercent.value >=
            page.value.levelReviewQuizPassingScore
        )
    })

    const isPassingScoreMetInParentQuestions = computed(() => {
        if (!page.value?.levelReviewQuizEnabled) return undefined
        if (!page.value.levelReviewQuizPassingScore) return undefined

        const totalParentQuestions = parentQuizItems.value.length
        if (totalParentQuestions === 0) return true

        const totalCorrectParentQuestions =
            totalParentQuestions - levelReviewQuizItems.value.length

        const correctPercent = Math.floor(
            (totalCorrectParentQuestions / totalParentQuestions) * 100
        )
        return correctPercent >= page.value.levelReviewQuizPassingScore
    })

    function addAnswers(
        responses: ResponseState[],
        quizItems: DeepReadonly<QuizItem[]>
    ) {
        logger(debug, { responses, quizItems })
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const levelReviewAnswersCollection = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('level-review-answers')

        const batch = firestore.batch()

        responses.forEach((r) => {
            if (r.hasChange) {
                const levelReviewAnswerDoc = r.answerId
                    ? levelReviewAnswersCollection.doc(r.answerId)
                    : levelReviewAnswersCollection.doc()
                const isCorrect = isResponseCorrect(r, quizItems)
                const data = {
                    question: r.question,
                    attempt: r.answerId ? r.attempt : r.attempt + 1,
                    ...(r.answerId === undefined && {
                        createdAt: serverTimestamp(),
                    }),
                    ...(r.questionType === 'text'
                        ? { text: r.text }
                        : r.questionType === 'range'
                          ? { range: r.range, isCorrect: isCorrect ?? false }
                          : {
                                choices: r.choices,
                                isCorrect: isCorrect ?? false,
                            }),
                }
                batch.set(levelReviewAnswerDoc, data)
            }
        })

        return batch.commit()
    }

    async function fetchLevelReviewAnswer(
        questionId: string
    ): Promise<LevelReviewAnswer | undefined> {
        logger(debug, questionId)
        if (viewer.value === undefined) throw 'Viewr is not initialized'

        const answerSnapshot = await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('level-review-answers')
            .where('question', '==', questionId)
            .orderBy('attempt')
            .limitToLast(1)
            .get()

        if (answerSnapshot.empty) {
            return undefined
        } else {
            return docData<LevelReviewAnswer>(answerSnapshot.docs[0])
        }
    }

    async function getScorableQuizItemsInPage(levelId: string, pageId: string) {
        logger(debug, { levelId, pageId })

        setQuestionCollection(levelId, pageId)
        const scorableQuestions = await fetchScorableQuestions()

        if (!scorableQuestions) return undefined
        const quizItemsInPage: LevelReviewQuizItem[] = await Promise.all(
            scorableQuestions?.map(async (question) => {
                setQuestionCollection(levelId, pageId)
                const choices = await fetchChoices(question.id)
                const answer = await fetchAnswer(question.id)
                return {
                    question,
                    choices,
                    answer,
                    pageId,
                }
            })
        )
        return quizItemsInPage
    }

    async function loadLevelReviewAnswers() {
        logger(debug)

        return Promise.all(
            levelReviewQuizItems.value.map(
                async ({ question }, index) =>
                    (levelReviewQuizItems.value[index].answer =
                        await fetchLevelReviewAnswer(question.id))
            )
        )
    }

    async function loadParentQuizItems(levelId: string) {
        logger(debug, levelId)
        if (page.value === undefined) throw 'Level page is not initialized'

        setLevel(levelId)
        branchRootPages.value = await fetchPreviousRootPages(page.value.num)

        let stopAtPageWithLevelReviewQuiz = false
        const previousQuizzesInLevelPromises = branchRootPages.value.map(
            async (page) => {
                if (stopAtPageWithLevelReviewQuiz) return undefined
                if (page.levelReviewQuizEnabled) {
                    stopAtPageWithLevelReviewQuiz = true
                    return undefined
                }
                return getScorableQuizItemsInPage(levelId, page.id)
            }
        )
        const previousQuizzesInLevel = (
            await Promise.all(previousQuizzesInLevelPromises)
        )
            .filter(
                (quizItems): quizItems is LevelReviewQuizItem[] =>
                    quizItems !== undefined
            )
            .flat()

        parentQuizItems.value = previousQuizzesInLevel
    }

    async function loadLevelReviewQuiz(levelId: string, pageId: string) {
        logger(debug, { levelId, pageId })

        await loadParentQuizItems(levelId)

        levelReviewQuizItems.value = parentQuizItems.value
            .filter(
                ({ answer, question }) =>
                    (question.type === 'text' &&
                        (answer?.text === undefined || answer.text === '')) ||
                    (question.type !== 'text' &&
                        (answer?.isCorrect === undefined || !answer.isCorrect))
            )
            .map(({ answer, choices, question, pageId }) => {
                return {
                    answer,
                    choices,
                    question: { ...question, condition: 'requireAnswer' },
                    pageId,
                }
            })

        await loadLevelReviewAnswers()
    }

    async function retakeLevelReviewQuiz(pageId: string) {
        logger(debug, pageId)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const batch = firestore.batch()

        const levelReviewAnswersCollection = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('level-review-answers')

        levelReviewQuizItems.value.forEach(({ answer, question }) => {
            const newLevelReviewAnswerDoc = levelReviewAnswersCollection.doc()
            const data = {
                question: question.id,
                attempt: (answer?.attempt ?? 1) + 1,
                createdAt: serverTimestamp(),
            }
            batch.set(newLevelReviewAnswerDoc, data)
        })

        return batch.commit()
    }

    function shuffleQuizOrder() {
        logger(debug)

        levelReviewQuizItems.value.sort(() => Math.random() - 0.5)
    }

    function deinitLevelPageReviewQuiz() {
        logger(debug)

        levelReviewQuizItems.value = []
        parentQuizItems.value = []
        branchRootPages.value = []
    }

    return {
        levelReviewQuizItems: readonly(levelReviewQuizItems),
        branchRootPages: readonly(branchRootPages),
        isPassingScoreMet,
        isPassingScoreMetInParentQuestions,
        levelReviewQuizScore,
        overallCorrectPercent,

        addAnswers,
        deinitLevelPageReviewQuiz,
        loadLevelReviewQuiz,
        retakeLevelReviewQuiz,
        shuffleQuizOrder,
    }
}
