import appConfig from '@/app-config'

/**
 * Log to console if not production env and enable is true. Ignored by eslint.
 * Supported only by Chrome and Chromium-based browsers due to use of [Error.stack](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack)
 * to infer context.
 */
export function logger(enable: boolean, ...args: unknown[]): void {
    if (appConfig.isProductionMode || !enable) return

    const hasArgs = args.length > 0
    const stack = new Error().stack
    if (!stack) return
    const [context, ...rest] = stack
        .toString()
        .split('\n')[2]
        .replaceAll('(webpack-internal:///./src', '')
        .split(':')[0]
        .replaceAll('at ', '')
        .trim()
        .split('/')
        .map((s) => s.trim())

    // eslint-disable-next-line no-console
    console.log(
        hasArgs ? `${context}:` : context,
        ...args,
        `\n    in ${rest.join('/')}`
    )
}

/**
 * Log error to console. Ignored by eslint.
 */
export function loggerError(...args: unknown[]): void {
    // eslint-disable-next-line no-console
    console.error(...args)
}
