import { firestore } from 'firebase/app'

export type Note = {
    id: string

    name?: string
    text: string

    private: boolean
    range?: TimeRange
    seconds?: number

    category?: VideoNoteCategory

    createdAt: firestore.Timestamp
    readAt?: firestore.Timestamp
    updatedAt?: firestore.Timestamp
    /** User ID */
    updatedBy?: string
}

export type FeedbackNote = Omit<Note, 'range' | 'text'> & {
    range: TimeRange
    text: FeedbackNoteType
}

export type TimeRange = {
    start: number
    end: number
}

export enum FeedbackNoteType {
    Engagement = 'Engagement',
    Disengagement = 'Disengagement',
    OpenEndedQuestion = 'Open-Ended Question',
    Affirmation = 'Affirmation',
    Reflection = 'Reflection',
    SummaryStatement = 'Summary Statement',
    EngagementDisengagement = 'Engagement / Disengagement',
    'MomNotes' = 'Mom Notes',
}

export const videoNoteCategoryOptions = ['self', 'mom'] as const

export type VideoNoteCategory = (typeof videoNoteCategoryOptions)[number]
