// eslint-disable-next-line
export type TemplateRef<T extends abstract new (...args: any) => any> =
    | InstanceType<T>
    | undefined

// eslint-disable-next-line
export type TemplateRefArray<T extends abstract new (...args: any) => any> =
    InstanceType<T>[]

/**
 * Util type to make specific fields in set K optional on type T
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

/**
 * Type predicate helper for determining whether value is nullish
 *
 * @param item a value
 * @returns type predicate boolean indicating whether `item` is non-nullish
 */
export function nonNullish<T>(item: T | undefined | null): item is T {
    return item !== null && item !== undefined
}

/**
 * Type predicate helper for determining whether value is falsy
 *
 * @param item a value
 * @returns type predicate boolean indicating whether `item` is non-falsy
 */
export function nonFalsy<T>(item: T | undefined | null): item is T {
    return Boolean(item)
}
