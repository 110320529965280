export type CSSRSMessageSafetyCard = {
    header: Array<string>
    headerEs: Array<string>
    bullets?: Array<string>
    bulletsEs?: Array<string>
}

export type CSSRSMessageSafety = {
    severity: 'low' | 'medium' | 'high'
    goingOn: CSSRSMessageSafetyCard
    whatDo: CSSRSMessageSafetyCard
}

export const resourcesMessageSafetyCard: CSSRSMessageSafety[] = [
    {
        severity: 'low',
        goingOn: {
            header: ["You need support; It's not an emergency"],
            headerEs: ['Necesita apoyo; no es una emergencia'],
            bullets: [
                "Your depression symptoms are worsening: You're feeling sad, worried, discouraged.",
                'You have had thoughts that it would be easier to go to sleep and not wake up or not be around anymore, and you would never act on these thoughts.',
            ],
            bulletsEs: [
                'Sus síntomas de depresión están empeorando: Usted se sientes triste, preocupado, desanimado',
                'Usted ha tenido pensamientos de que sería más fácil irse a dormir y no despertarse o ya no estar, y nunca actuaría sobre estos pensamientos',
            ],
        },
        whatDo: {
            header: ['Talk to your coach on your next call:'],
            headerEs: ['Hable con su entrenador en su próxima llamada:'],
            bullets: [
                "Reach out to your therapist if you have one;If you don't, consider finding a therapist.",
                'Use the tools you have to improve your mood: - Downward mood spiral? Change direction by doing something you enjoy; use mindfulness strategies.',
            ],
            bulletsEs: [
                'Acuda a su terapeuta si tiene uno; si no lo tiene, considere buscar un terapeuta',
                'Utilice las herramientas que tiene para mejorar su estado de ánimo: ¿Espiral de mal humor? Cambie de dirección haciendo algo que le guste; utilice estrategias de mindfulness',
            ],
        },
    },
    {
        severity: 'medium',
        goingOn: {
            header: ['It could become an emergency day or night:'],
            headerEs: ['Puede convertirse en una emergencia de día o de noche'],
            bullets: [
                'You are having thoughts about suicide but will not act on them.',
                'You are safe right now but might hurt yourself in the future.',
            ],
            bulletsEs: [
                'Usted tiene pensamientos suicidas, pero no los llevará a cabo',
                'Ahora usted está a salvo, pero podría hacerse daño en el futuro',
            ],
        },
        whatDo: {
            header: [
                'Call or text',
                '988',
                'http://wwww.suicidepreventionlifeline.org',
                'click the chat button on the',
                'https://988lifeline.org/chat/?ga=2.192903402.1998777417.1659219508-2045693340.1658846076',
                'to reach trained and caring people.',
            ],
            headerEs: [
                'Llame o envíe un mensaje al',
                '988',
                'http://wwww.suicidepreventionlifeline.org',
                'haga clic en el botón de chat de',
                'https://988lifeline.org/chat/?ga=2.192903402.1998777417.1659219508-2045693340.1658846076',
                'para llegar a personas formadas y solidarias.',
            ],
            bullets: [],
            bulletsEs: [],
        },
    },

    {
        severity: 'high',
        goingOn: {
            header: ["It's a life-threatening emergency:"],
            headerEs: ['Es una emergencia que amenaza la vida:'],
            bullets: [
                'Someone needs medical attention',
                'You have hurt yourself',
                'You are in danger of hurting yourself',
            ],
            bulletsEs: [
                'Alguien necesita atención médica',
                'Se ha hecho daño',
                'Corre peligro de hacerse daño',
            ],
        },
        whatDo: {
            header: ['CALL', '911'],
            headerEs: ['LLAME AL', '911'],
        },
    },
]
