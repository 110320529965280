import { readonly, ref } from 'vue'

import {
    storage,
    uploadStateChangedEvent,
    StorageRef,
    UploadTask,
} from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'

const debug = false

export type UploadType =
    | 'closedCaption'
    | 'featureIcon'
    | 'feedbackAudio'
    | 'feedbackVideo'
    | 'levelIcon'
    | 'levelBanner'
    | 'pageAudio'
    | 'pageImage'
    | 'pageNarration'
    | 'pageVideo'
    | 'resourceAudio'
    | 'resourceDocument'
    | 'resourceImage'
    | 'resourceNarration'
    | 'resourceVideo'
    | 'userVideo'
    | 'loginLogo'
    | 'loginMedia'
    | 'quizImmediateFeedback'
    | 'questionNarration'
    | 'questionBucketImage'
    | 'transcriptAudio'
    | 'voiceResponse'

export type UploadResult = {
    downloadUrl: URL
    name: string
    size: number
    uploadType: UploadType
}

const fileInputRef = ref<HTMLInputElement | undefined>()
const fileEventHandlers = ref<((_: Event) => void)[]>([])

export default function (type?: UploadType) {
    const { viewer } = useViewer()
    const { activeCourse } = useCourses()

    const file = ref<File | undefined>()
    const filePreviewUrl = ref<string | undefined>()

    const uploadProgress = ref(0)

    const uploadTask = ref<UploadTask | undefined>()
    const cleanupTask = ref<() => void | undefined>()

    function setFileInputRef(inputEl: HTMLInputElement) {
        fileInputRef.value = inputEl
    }

    function resetProgress() {
        logger(debug)
        uploadProgress.value = 0
    }

    function cancel() {
        logger(debug)
        resetProgress()

        if (filePreviewUrl.value !== undefined) {
            // Release object URL from memory
            URL.revokeObjectURL(filePreviewUrl.value)
        }

        file.value = undefined
        filePreviewUrl.value = undefined
        uploadTask.value?.cancel()
        cleanupTask.value?.()
    }

    function pause() {
        logger(debug)
        uploadTask.value?.pause()
    }

    function resume() {
        logger(debug)
        uploadTask.value?.resume()
    }

    /**
     * Reads a file from disk and loads its data and a preview URL into state.
     *
     * @param event a file input event containing one file
     */
    function handleFileSelection(event: Event) {
        logger(debug, event)
        if (fileInputRef.value === undefined) throw 'File input unavailable'

        const target = event.target as HTMLInputElement
        if (target.files === null || target.files.length === 0) {
            throw 'Invalid file input'
        }

        const [localFile] = target.files

        file.value = localFile
        filePreviewUrl.value = URL.createObjectURL(localFile)
    }

    function addFileInputListener(handler: (event: Event) => void) {
        logger(debug, handler)
        if (fileInputRef.value === undefined) throw 'File input unavailable'

        fileEventHandlers.value.push(handler)
        fileInputRef.value.addEventListener('change', handler)
    }

    function addCancelListener(handler: () => void) {
        logger(debug, handler)
        if (fileInputRef.value === undefined) throw 'File input unavailable'

        fileEventHandlers.value.push(handler)
        fileInputRef.value.addEventListener('cancel', handler)
    }

    function resetFileInput() {
        logger(debug)
        if (fileInputRef.value === undefined) throw 'File input unavailable'

        fileInputRef.value.value = fileInputRef.value.defaultValue
        fileEventHandlers.value.forEach((handler) => {
            fileInputRef.value?.removeEventListener('change', handler)
            fileInputRef.value?.removeEventListener('cancel', handler)
        })
    }

    function selectFile(options?: {
        capture?: boolean
        onCancel?: () => void
    }) {
        logger(debug, options)
        if (fileInputRef.value === undefined) throw 'File input unavailable'
        if (type === undefined) throw 'File type unspecified'

        // Clear preexisting local upload state
        cancel()

        fileInputRef.value.toggleAttribute('capture', options?.capture ?? false)

        switch (type) {
            case 'featureIcon':
                fileInputRef.value.accept = '.svg'
                break
            case 'closedCaption': {
                fileInputRef.value.accept = '.vtt'
                break
            }

            case 'feedbackAudio':
            case 'pageAudio':
            case 'pageNarration':
            case 'quizImmediateFeedback':
            case 'questionNarration':
            case 'resourceAudio':
            case 'resourceNarration':
            case 'transcriptAudio':
            case 'voiceResponse': {
                fileInputRef.value.accept = 'audio/*'
                break
            }

            case 'resourceDocument': {
                fileInputRef.value.accept = 'application/pdf'
                break
            }

            case 'levelBanner':
            case 'levelIcon':
            case 'loginLogo':
            case 'loginMedia':
            case 'pageImage':
            case 'resourceImage': {
                fileInputRef.value.accept = 'image/*'
                break
            }

            case 'questionBucketImage': {
                fileInputRef.value.accept = 'image/*'
                break
            }

            case 'feedbackVideo':
            case 'pageVideo':
            case 'resourceVideo':
            case 'userVideo': {
                fileInputRef.value.accept = 'video/*'
                break
            }
        }

        resetFileInput()
        addFileInputListener(handleFileSelection)
        if (options?.onCancel) {
            addCancelListener(options?.onCancel)
        }

        // Open device file picker
        fileInputRef.value.click()
    }

    /**
     * Starts an upload task to Storage. Updates progress and rejects or resolves
     * when the task fails or completes, respectively.
     *
     * @param storageRef a Storage ref location to upload the file
     * @param file the file data to upload
     * @param metadata an optional object containing metadata to upload with the file data
     * @returns a promise which resolves with an object containing the uploaded file's download URL, name and size
     */
    async function uploadFile(
        storageRef: StorageRef,
        file: File,
        metadata?: { contentType: string }
    ) {
        logger(debug)
        resetProgress()
        return new Promise<UploadResult>((resolve, reject) => {
            const task = storageRef.put(file, metadata)
            uploadTask.value = task

            const unsubscribeFromTask = task.on(
                uploadStateChangedEvent,
                // On progress
                (t) => {
                    uploadProgress.value = t.bytesTransferred / t.totalBytes
                },
                // On error
                (error) => {
                    resetProgress()
                    reject(error)
                },
                // On complete
                async () => {
                    if (type === undefined) {
                        reject('Unknown upload type')
                    } else {
                        const snapshot = task.snapshot
                        resolve({
                            downloadUrl: await snapshot.ref.getDownloadURL(),
                            name: snapshot.metadata.name,
                            size: snapshot.metadata.size,
                            uploadType: type,
                        })
                    }
                }
            )

            cleanupTask.value = () => {
                unsubscribeFromTask()
                reject() // Upload cancelled
            }
        })
    }

    /**
     * Constructs a Storage ref for the currently selected file and initiates upload.
     * Throws an error if there is no selected file.
     *
     * @param fileName the name at the end of the file's Storage ref; overwrites file at ref if one exists
     * @param refOptions string options used to construct the file's Storage ref
     * @param courseId an optional course id in case its different than the active course
     * @returns a promise that resolves when file finishes uploading successfully
     */
    async function uploadSelectedFile(
        fileName: string,
        refOptions?: {
            levelId?: string
            pageId?: string
            resourceId?: string
            questionId?: string
            loginElementType?: string
        },
        courseId?: string
    ) {
        logger(debug, fileName, refOptions)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }
        if (file.value === undefined) {
            throw 'No file selected for upload'
        }

        const { levelId, pageId, resourceId, questionId, loginElementType } =
            refOptions ?? {}
        const fileMetadata = { contentType: file.value.type }

        // Prevent Firebase Storage from interpretting name as URI
        const safeFileName = fileName.replaceAll('/', '-')
        switch (type) {
            case 'levelBanner':
            case 'levelIcon': {
                if (levelId === undefined) throw 'Level ID not specified'

                const iconRef = storage
                    .ref()
                    .child('courses')
                    .child(activeCourse.value.id)
                    .child('levels')
                    .child(levelId)
                    .child(safeFileName)

                return uploadFile(iconRef, file.value, fileMetadata)
            }

            case 'closedCaption':
            case 'feedbackAudio':
            case 'feedbackVideo':
            case 'pageAudio':
            case 'pageImage':
            case 'pageNarration':
            case 'pageVideo':
            case 'transcriptAudio': {
                if (levelId === undefined) throw 'Level ID not specified'
                if (pageId === undefined) throw 'Page ID not specified'

                const mediaRef = storage
                    .ref()
                    .child('courses')
                    .child(activeCourse.value.id)
                    .child('levels')
                    .child(levelId)
                    .child('pages')
                    .child(pageId)
                    .child(safeFileName)

                return uploadFile(mediaRef, file.value, fileMetadata)
            }
            case 'questionNarration': {
                if (levelId === undefined) throw 'Level ID not specified'
                if (pageId === undefined) throw 'Page ID not specified'
                if (questionId === undefined) throw 'Question ID not specified'

                const mediaRef = storage
                    .ref()
                    .child('courses')
                    .child(activeCourse.value.id)
                    .child('levels')
                    .child(levelId)
                    .child('pages')
                    .child(pageId)
                    .child('questions')
                    .child(questionId)
                    .child(safeFileName)

                return uploadFile(mediaRef, file.value, fileMetadata)
            }
            case 'questionBucketImage': {
                if (levelId === undefined) throw 'Level ID not specified'
                if (pageId === undefined) throw 'Page ID not specified'
                if (questionId === undefined) throw 'Question ID not specified'

                const mediaRef = storage
                    .ref()
                    .child('courses')
                    .child(activeCourse.value.id)
                    .child('levels')
                    .child(levelId)
                    .child('pages')
                    .child(pageId)
                    .child('questions')
                    .child(questionId)
                    .child(safeFileName)

                return uploadFile(mediaRef, file.value, fileMetadata)
            }
            case 'resourceAudio':
            case 'resourceDocument':
            case 'resourceImage':
            case 'resourceNarration':
            case 'resourceVideo': {
                if (resourceId === undefined) throw 'Resource ID not specified'

                const mediaRef = storage
                    .ref()
                    .child('courses')
                    .child(activeCourse.value.id)
                    .child('resources')
                    .child(resourceId)
                    .child(safeFileName)

                return uploadFile(mediaRef, file.value, fileMetadata)
            }

            case 'userVideo': {
                const videoRef = storage
                    .ref()
                    .child('users')
                    .child(viewer.value.id)
                    .child('uploads')
                    .child(safeFileName)

                return uploadFile(videoRef, file.value, fileMetadata)
            }
            case 'voiceResponse': {
                const videoRef = storage
                    .ref()
                    .child('users')
                    .child(viewer.value.id)
                    .child('uploads')
                    .child('voice')
                    .child(safeFileName)

                return uploadFile(videoRef, file.value, fileMetadata)
            }

            case 'loginMedia': {
                if (loginElementType === undefined)
                    throw 'Login Element Type not specified'
                const mediaRef = storage
                    .ref()
                    .child('login')
                    .child(loginElementType)
                    .child(safeFileName)

                return uploadFile(mediaRef, file.value, fileMetadata)
            }
            case 'loginLogo': {
                const mediaRef = storage.ref().child('logo').child(safeFileName)

                return uploadFile(mediaRef, file.value, fileMetadata)
            }
            case 'quizImmediateFeedback': {
                if (courseId === undefined) throw 'Course ID not specified'
                const mediaRef = storage
                    .ref()
                    .child('courses')
                    .child(courseId)
                    .child('quizImmediateFeedback')
                    .child(safeFileName)

                return uploadFile(mediaRef, file.value, fileMetadata)
            }

            default: {
                throw `Invalid file type: "${type}"`
            }
        }
    }

    async function setFileFromUrl(url: string) {
        logger(debug, url)

        // Important notice: the method fetch won't work on live reload with the ionic cli
        const res = await fetch(url)
        const blob = await res.blob()

        filePreviewUrl.value = url
        file.value = new File([blob], 'filename.mp4', {
            type: 'video/mp4',
        })
    }

    async function setFileFromBase64(base64: string, contentType?: string) {
        const binaryString = window.atob(base64)
        const len = binaryString.length
        const bytes = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
        }
        const blob = new Blob([bytes], { type: contentType })
        filePreviewUrl.value = URL.createObjectURL(blob)
        file.value = new File([blob], 'filename.webm', { type: contentType })
    }

    return {
        file: readonly(file),
        filePreviewUrl: readonly(filePreviewUrl),
        uploadProgress: readonly(uploadProgress),

        cancel,
        pause,
        resume,
        selectFile,
        setFileFromBase64,
        setFileFromUrl,
        setFileInputRef,
        uploadSelectedFile,
    }
}
