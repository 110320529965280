export type Moment = {
    id: string
    text: string
    textEs: string
    module: number
    positiveFeedbackEn: string
    positiveFeedbackEs: string
    negativeFeedbackEn: string
    negativeFeedbackEs: string
}

export const moments: Moment[] = [
    {
        id: '1',
        text: 'How do you feel today about connecting with your child through play?',
        textEs: '¿Cómo te sientes hoy sobre conectar con tu hijo o hija a través del juego?',
        module: 1,
        positiveFeedbackEn:
            '/assets/mysibtime/audio/moments/Moments_Play _English_1.mp4',
        positiveFeedbackEs:
            '/assets/mysibtime/audio/moments/Moments_Play_Spanish_1.m4v',
        negativeFeedbackEn:
            '/assets/mysibtime/audio/moments/Moments_Play _English_1.mp4',
        negativeFeedbackEs:
            '/assets/mysibtime/audio/moments/Moments_Play_Spanish_1.m4v',
    },
]
