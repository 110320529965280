import { readonly, ref, Ref } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import { PleasantActivity, ActivityType } from '@/models/users/activity'

const debug = false

const activities: Ref<PleasantActivity[]> = ref([])

export default function () {
    const { viewer } = useViewer()

    async function addActivities(activitiesData: {
        type: ActivityType
        activities: { id: number; text: string }[]
    }) {
        logger(debug, activitiesData)
        if (viewer.value === undefined) {
            throw 'Cannot add activity for undefined viewer'
        }

        const activitiesCollectionRef = firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('activities')

        const batch = firestore.batch()
        activitiesData.activities.forEach((a) => {
            const activityRef = activitiesCollectionRef.doc()
            batch.set(activityRef, {
                createdAt: serverTimestamp(),
                type: activitiesData.type,
                text: a.text,
                ...(a.id > 0 && { defaultId: a.id }),
            })
        })

        return batch.commit()
    }

    async function fetchActivities(userId?: string) {
        logger(debug, userId ?? viewer.value?.id)
        if (userId === undefined && viewer.value?.id === undefined) {
            throw 'Cannot fetch activities for undefined'
        }

        const activitiesSnapshot = await firestore
            .collection('users')
            .doc(userId ?? viewer.value?.id)
            .collection('activities')
            .orderBy('createdAt', 'desc')
            .get()

        activities.value =
            getCollectionDocs<PleasantActivity>(activitiesSnapshot)
    }

    async function deleteActivity(activityId: string) {
        logger(debug, activityId)
        if (viewer.value === undefined) {
            throw 'Cannot delete viewer not initialized'
        }

        await firestore
            .collection('users')
            .doc(viewer.value.id)
            .collection('activities')
            .doc(activityId)
            .delete()

        activities.value = activities.value.filter((a) => a.id !== activityId)
    }

    function deinitActivities() {
        logger(debug)
        activities.value = []
    }

    return {
        activities: readonly(activities),

        addActivities,
        deinitActivities,
        deleteActivity,
        fetchActivities,
    }
}
