import { reactive, readonly, ref, onUnmounted, Ref } from 'vue'

import router from '@/router'

import { logger } from '@/utils/debug'

const debug = false

type LevelPageActions = {
    navPrevious?: () => void
    navNext?: () => void
}
export type TabItem = {
    id: string
    label: string
    icon: string
    href: string
}

const levelPageActions: LevelPageActions = reactive({
    navPrevious: undefined,
    navNext: undefined,
})
const levelPageProgress = ref(0)
const navNextDisabled = ref(false)
const showNavPrevious = ref(false)
const isFooterVisible = ref(true)

const navNextDelayCountdown: Ref<number | undefined> = ref(undefined)
const interval: Ref<ReturnType<typeof setInterval> | undefined> = ref(undefined)

const tabItems = ref<TabItem[]>([])

export default function () {
    function setActions(actions: LevelPageActions) {
        logger(debug, actions)
        navNextDelayCountdown.value = undefined
        clearInterval(interval.value)
        levelPageActions.navPrevious = actions.navPrevious
        levelPageActions.navNext = actions.navNext
    }

    function updateProgressBar(progress: number) {
        logger(debug, progress)
        levelPageProgress.value = progress
    }

    function setNavNextDelay(callback: () => void) {
        logger(debug)
        if (navNextDelayCountdown.value) return

        navNextDisabled.value = true
        navNextDelayCountdown.value = 300 // Disable button for 5 minutes
        interval.value = setInterval(() => {
            if (navNextDelayCountdown.value === 0) {
                navNextDisabled.value = false
                navNextDelayCountdown.value = undefined
                clearInterval(interval.value)
                callback()
            } else {
                navNextDelayCountdown.value && navNextDelayCountdown.value--
            }
        }, 1000)
    }

    function setNavNextDisabled(disabled: boolean) {
        logger(debug, disabled)
        navNextDisabled.value = disabled
    }

    function setNavPreviousShowing(showing: boolean) {
        logger(debug, showing)
        showNavPrevious.value = showing
    }

    function setTabItems(items: TabItem[]) {
        logger(debug, items)
        tabItems.value = items
    }

    function hideFooter() {
        logger(debug)
        isFooterVisible.value = false
    }

    function showFooter() {
        logger(debug)
        isFooterVisible.value = true
    }

    onUnmounted(() => {
        if (router.currentRoute.value.name !== 'LevelPage') {
            // Reset progress bar between levels
            levelPageProgress.value = 0
        }
        tabItems.value = []
    })

    return {
        isFooterVisible: readonly(isFooterVisible),
        levelPageActions: levelPageActions
            ? readonly(levelPageActions)
            : undefined,
        levelPageProgress: readonly(levelPageProgress),
        navNextDelayCountdown: readonly(navNextDelayCountdown),
        navNextDisabled: readonly(navNextDisabled),
        showNavPrevious: readonly(showNavPrevious),
        tabItems: readonly(tabItems),

        hideFooter,
        setActions,
        setNavNextDisabled,
        setNavNextDelay,
        setNavPreviousShowing,
        setTabItems,
        showFooter,
        updateProgressBar,
    }
}
