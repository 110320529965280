import { ref } from 'vue'

import { logger } from '@/utils/debug'
import { confirmAlert } from '@/utils/ion-alert'

const debug = false

const hasUnsavedChanges = ref(false)

export default function () {
    async function confirmDiscardUnsavedChanges() {
        logger(debug)

        if (!hasUnsavedChanges.value) return true

        const confirmed = await confirmAlert({
            title: 'Are you sure?',
            confirmLabel: 'Discard Changes',
            message: 'You will lose any unsaved changes.',
            destructive: true,
        })

        if (confirmed) hasUnsavedChanges.value = false
        return confirmed
    }

    function guardUnsavedChanges(hasChanges: boolean) {
        logger(debug, hasChanges)
        hasUnsavedChanges.value = hasChanges
    }

    return {
        confirmDiscardUnsavedChanges,
        guardUnsavedChanges,
    }
}
