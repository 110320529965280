import { differenceInDays } from 'date-fns'
import { readonly, Ref, ref } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'
import { FirestoreTimestamp } from '@/firebase-config'

import useCourse from '@/composables/global/use-courses'
import useUsers from '@/composables/global/use-users'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import { User } from '@/models/user'
import { Phq9, ResponseScore } from '@/models/users/phq9'

const debug = false

const phq9s: Ref<Phq9[]> = ref([])

// Viewers with the roles admin, guide*, or manager will have this populated
// with each participant id they are assigned to see
// who has an unviewed worsening symptoms phq9 report
const usersWithUnviewedPhq9Reports: Ref<string[]> = ref([])

type UserPhq9Report = {
    // User
    displayName?: string
    name: string
    researchId?: string

    // Phq-9
    byGuide?: boolean
    createdAt: FirestoreTimestamp
    totalScore: number // 0-27
    worseningSymptoms?: boolean
    q1: ResponseScore
    q2: ResponseScore
    q3: ResponseScore
    q4: ResponseScore
    q5: ResponseScore
    q6: ResponseScore
    q7: ResponseScore
    q8: ResponseScore
    q9: ResponseScore
}

export default function () {
    const { isAdmin, isEditor, isGuide, isManager, isParticipant, viewer } =
        useViewer()
    const { isOptionSet } = useCourse()
    const { getUser } = useUsers()

    function getT1Score(userId?: string) {
        logger(debug)

        if (userId) {
            const user = getUser(userId)
            if (user?.properties?.t1) return user?.properties?.t1
            throw `User ${userId} is missing the PHQ-9 T1 score, please see user's settings.`
        } else if (viewer.value?.properties?.t1 === undefined) {
            throw "Viewer is missing the PHQ-9 T1 score, please see user's settings."
        }
        return viewer.value?.properties?.t1
    }

    function hasWorseningSymptoms(responseScore: number, t1Score: number) {
        logger(debug, responseScore, t1Score)

        const numberOfPhqs = phq9s.value.length
        if (numberOfPhqs < 2) return false
        if (responseScore === -1) return false

        // 20% more then the t1 score, no exceptions for scores 23 or higher
        const worseningSymptom = t1Score * 1.2
        const lastPhq9TotalScore = phq9s.value[numberOfPhqs - 1].totalScore
        const secondToLastPhq9TotalScore =
            phq9s.value[numberOfPhqs - 2].totalScore

        const priorPhq9sHaveWorseningSymptoms =
            lastPhq9TotalScore >= 10 &&
            lastPhq9TotalScore >= worseningSymptom &&
            secondToLastPhq9TotalScore >= 10 &&
            secondToLastPhq9TotalScore >= worseningSymptom
        const newPhq9HasWorseningSymptoms =
            responseScore >= 10 && responseScore >= worseningSymptom

        return priorPhq9sHaveWorseningSymptoms && newPhq9HasWorseningSymptoms
    }

    async function addPhq9Response(data: {
        byGuide?: boolean
        userId?: string
        phq9Data: number[]
    }) {
        logger(debug, data)
        const { byGuide, userId, phq9Data } = data
        const userDocId = userId ?? viewer.value?.id
        if (userDocId === undefined) throw 'User ID is undefined'

        let responseScoresSum = 0
        let totalResponses = 0
        phq9Data.forEach((response) => {
            if (response <= 3) {
                responseScoresSum += response
                totalResponses++
            }
        })
        // If there are less then 7 reponses no total score should be calculated
        if (totalResponses < 7) {
            responseScoresSum = -1
        }
        // Any skipped questions count as the average response of the sum
        else if (totalResponses < 9 && totalResponses !== 0) {
            const averageResponse = responseScoresSum / totalResponses
            responseScoresSum += averageResponse * (9 - totalResponses)
        }

        try {
            const t1Score = getT1Score(userId)

            const worseningSymptoms = hasWorseningSymptoms(
                responseScoresSum,
                t1Score
            )

            await firestore
                .collection('users')
                .doc(userDocId)
                .collection('phq9s')
                .add({
                    byGuide,
                    createdAt: serverTimestamp(),
                    q1: phq9Data[0],
                    q2: phq9Data[1],
                    q3: phq9Data[2],
                    q4: phq9Data[3],
                    q5: phq9Data[4],
                    q6: phq9Data[5],
                    q7: phq9Data[6],
                    q8: phq9Data[7],
                    q9: phq9Data[8],
                    totalScore: responseScoresSum,
                    worseningSymptoms: worseningSymptoms,
                })
            return Promise.resolve()
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async function fetchPhq9Responses(userId?: string) {
        logger(debug, userId)

        const userDocId = userId ?? viewer.value?.id
        if (!userDocId) {
            return []
        }

        const phq9sSnapshot = await firestore
            .collection('users')
            .doc(userDocId)
            .collection('phq9s')
            .orderBy('createdAt')
            .get()

        if (phq9sSnapshot.empty) {
            return []
        } else {
            return getCollectionDocs<Phq9>(phq9sSnapshot)
        }
    }

    async function fetchUsersLastTwoPhq9s(userId: string) {
        logger(debug, userId)

        const phq9sSnapshot = await firestore
            .collection('users')
            .doc(userId)
            .collection('phq9s')
            .orderBy('createdAt')
            .limitToLast(2)
            .get()

        if (phq9sSnapshot.empty) {
            return []
        } else {
            return getCollectionDocs<Phq9>(phq9sSnapshot)
        }
    }

    async function fetchUsersPhq9Reports(userList: User[]) {
        logger(debug)

        let allUsersPhq9: UserPhq9Report[] = []
        await Promise.all(
            userList.map(async (user) => {
                const phq9ReportsSnapshot = await firestore
                    .collection('users')
                    .doc(user.id)
                    .collection('phq9s')
                    .get()
                if (phq9ReportsSnapshot.empty) return
                const phq9Reports = getCollectionDocs<Phq9>(phq9ReportsSnapshot)

                const userListPhq9Reports: UserPhq9Report[] = phq9Reports.map(
                    (report) => ({
                        createdAt: report.createdAt,
                        totalScore: report.totalScore,
                        worseningSymptoms: report.worseningSymptoms,
                        byGuide: report.byGuide,
                        q1: report.q1,
                        q2: report.q2,
                        q3: report.q3,
                        q4: report.q4,
                        q5: report.q5,
                        q6: report.q6,
                        q7: report.q7,
                        q8: report.q8,
                        q9: report.q9,
                        researchId: user.researchId,
                        displayName: user.displayName,
                        name: user.name,
                    })
                )
                allUsersPhq9 = allUsersPhq9.concat(userListPhq9Reports)
            })
        )
        return allUsersPhq9
    }

    async function fetchUsersWithUnviewedPhq9Reports() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        const isJustEditor =
            isEditor.value &&
            !(isAdmin.value || isManager.value || isGuide.value)
        if (isParticipant.value || isJustEditor) {
            usersWithUnviewedPhq9Reports.value = []
            return
        }

        const unviewedPhq9ReportsSnapshot = await firestore
            .collectionGroup('phq9s')
            .where('hasNotBeenViewedBy', 'array-contains', viewer.value.id)
            .get()

        if (unviewedPhq9ReportsSnapshot.empty) {
            usersWithUnviewedPhq9Reports.value = []
            return
        } else {
            usersWithUnviewedPhq9Reports.value =
                unviewedPhq9ReportsSnapshot.docs.flatMap((doc) => {
                    if (doc.ref.parent.parent !== null) {
                        return doc.ref.parent.parent.id
                    }
                    return []
                })
        }
    }

    function getLastPhq9ResponseDate(phq9Data: Phq9[]) {
        logger(debug, phq9Data)

        const lastResponseDate = phq9Data.slice(-1)[0]?.createdAt

        return lastResponseDate?.toDate()
    }

    async function loadPhq9Responses() {
        logger(debug)

        if (!viewer.value?.id) {
            phq9s.value = []
            usersWithUnviewedPhq9Reports.value = []
            return
        }
        await fetchUsersWithUnviewedPhq9Reports()
        if (!isOptionSet('phq9')) {
            phq9s.value = []
            return
        }
        phq9s.value = await fetchPhq9Responses()
    }

    async function updatePhq9ReportViewer(
        reportId: string,
        userId: string,
        hasNotBeenViewedBy: string[]
    ) {
        logger(debug, reportId, userId, hasNotBeenViewedBy)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const viewerIndex = hasNotBeenViewedBy.indexOf(viewer.value.id)
        if (viewerIndex === -1) return
        // Remove viewer from the array of those who havent seen the report
        hasNotBeenViewedBy.splice(viewerIndex, 1)

        await firestore
            .collection('users')
            .doc(userId)
            .collection('phq9s')
            .doc(reportId)
            .update({
                hasNotBeenViewedBy: hasNotBeenViewedBy,
            })

        await fetchUsersWithUnviewedPhq9Reports()
    }

    async function userMustCompletePhq9(user?: User) {
        logger(debug, user)

        const isUserViewer = user === undefined

        // If the viewer is not "active" status they will not be prompted for PHQ9
        if (viewer.value !== undefined && isUserViewer) {
            if (viewer.value.status !== 'active') return false
        }

        if (!isOptionSet('phq9')) {
            return false
        } else if (isUserViewer && !isParticipant.value) {
            return false
        } else if (!isUserViewer && user.roles.length > 0) {
            return false
        }

        const userPhq9s = isUserViewer
            ? phq9s.value
            : await fetchPhq9Responses(user.id)
        const lastResponseDate = getLastPhq9ResponseDate(userPhq9s)
        if (lastResponseDate === undefined) {
            return true
        } else {
            const currentDate = new Date()
            const interval = differenceInDays(currentDate, lastResponseDate)
            // 13 Days Since Last Response
            return interval >= 13
        }
    }

    function deinitPhq9() {
        logger(debug)
        phq9s.value = []
    }

    return {
        usersWithUnviewedPhq9Reports: readonly(usersWithUnviewedPhq9Reports),

        addPhq9Response,
        deinitPhq9,
        fetchPhq9Responses,
        fetchUsersLastTwoPhq9s,
        fetchUsersPhq9Reports,
        loadPhq9Responses,
        userMustCompletePhq9,
        updatePhq9ReportViewer,
    }
}
