import {
    PushNotifications,
    ActionPerformed,
    PushNotificationSchema,
    Token,
    RegistrationError,
} from '@capacitor/push-notifications'
import { menuController } from '@ionic/vue'

import i18n from '@/i18n-config'
import Router, {
    CHAT_PATH,
    LEVELS_PATH,
    REMINDERS_PATH,
    RESOURCES_PATH,
    USERS_PATH,
    VIDEO_CALL_PATH,
} from '@/router'

import useCall from '@/composables/global/use-call'
import useCourses from '@/composables/global/use-courses'
import useSibtimeMoments from '@/composables/global/use-sibtime-moments'
import useViewer from '@/composables/global/use-viewer'

import { logger, loggerError } from '@/utils/debug'
import { errorAlert } from '@/utils/ion-alert'
import { longToast, shortToast } from '@/utils/ion-toast'

const debug = false

enum NotificationAction {
    GoToApp = 'goToApp',
    GoToChats = 'goToChats',
    GoToLevelPage = 'goToLevelPage',
    GoToResources = 'goToResoures',
    GoToReminder = 'goToReminders',
    GoToSessionLog = 'goToSessionLog',
    GoToUsers = 'goToUsers',
    GoToCallPage = 'goToCallPage',
    ShowDisplayName = 'showDisplayName',
}

export default function () {
    const { setIsCallPageOpenedByUser } = useCall()
    const { activeCourse } = useCourses()
    const { activateViewerDevice, isOnAndroid, isOnIos, isOnWeb } = useViewer()
    const { openSibTimeMomentModal } = useSibtimeMoments()

    async function addPushNotificationListners() {
        // On success, we should be able to receive notifications
        await PushNotifications.addListener('registration', (token: Token) => {
            activateViewerDevice(token)
        })

        // Some issue with our setup and push will not work
        await PushNotifications.addListener(
            'registrationError',
            async (error: RegistrationError) => {
                loggerError(error)
                longToast(i18n.global.t('PushNotifications.error'))
            }
        )

        // Handle notification received while in the app
        await PushNotifications.addListener(
            'pushNotificationReceived',
            (notification: PushNotificationSchema) => {
                switch (notification.data.action) {
                    case NotificationAction.GoToApp: {
                        if (notification.title === undefined) return
                        shortToast(notification.title)
                        break
                    }
                    case NotificationAction.ShowDisplayName: {
                        if (notification.title === undefined) return
                        shortToast(notification.title)
                        break
                    }
                    case NotificationAction.GoToLevelPage: {
                        if (notification.title === undefined) return
                        shortToast(notification.title)
                        break
                    }
                    case NotificationAction.GoToSessionLog: {
                        if (notification.title === undefined) return
                        shortToast(notification.title)
                        break
                    }
                    case NotificationAction.GoToResources: {
                        if (notification.body === undefined) return
                        shortToast(notification.body)
                        break
                    }
                    case NotificationAction.GoToReminder: {
                        if (
                            notification.title === undefined ||
                            notification.body === undefined
                        )
                            return
                        shortToast(
                            `${notification.title} \n${notification.body}`
                        )
                        break
                    }
                    case NotificationAction.GoToChats: {
                        if (notification.title === undefined) return
                        if (Router.currentRoute.value.fullPath !== CHAT_PATH) {
                            shortToast(
                                i18n.global.t('PushNotifications.newMessage', {
                                    user: notification.title,
                                })
                            )
                        }
                        break
                    }
                    case NotificationAction.GoToUsers: {
                        if (notification.title === undefined) return
                        shortToast(notification.title)
                        break
                    }
                    case NotificationAction.GoToCallPage: {
                        if (notification.title === undefined) return
                        if (
                            Router.currentRoute.value.fullPath !==
                            `${VIDEO_CALL_PATH}/${notification.data.userId}/${notification.data.chatId}/${notification.data.kind}`
                        ) {
                            shortToast(
                                i18n.global.t(
                                    'PushNotifications.incomingCall',
                                    {
                                        user: notification.title,
                                    }
                                ),
                                {
                                    icon:
                                        notification.data.kind === 'audio'
                                            ? 'call'
                                            : 'videocam',
                                    duration: 5000,
                                }
                            )
                        }
                        break
                    }
                    default: {
                        break
                    }
                }
            }
        )

        // Handle notification opened from outside the app
        await PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: ActionPerformed) => {
                await menuController.close()
                switch (notification.notification.data.action) {
                    case NotificationAction.ShowDisplayName: {
                        break
                    }
                    case NotificationAction.GoToApp: {
                        Router.push(LEVELS_PATH).catch((error) => {
                            errorAlert({ error })
                        })
                        if (notification.notification.data.screen === 'SibTime')
                            openSibTimeMomentModal()
                        break
                    }
                    case NotificationAction.GoToLevelPage: {
                        const courseId = notification.notification.data.courseId
                        const levelId = notification.notification.data.levelId
                        const pageId = notification.notification.data.pageId
                        if (courseId === undefined || levelId === undefined)
                            return
                        if (activeCourse.value?.id !== courseId) return
                        Router.push({
                            name: 'LevelPage',
                            params: {
                                levelId,
                                pageId,
                            },
                        }).catch((error) => {
                            errorAlert({ error })
                        })
                        break
                    }
                    case NotificationAction.GoToSessionLog: {
                        const userId = notification.notification.data.userId
                        const guideSessionId =
                            notification.notification.data.guideSessionId

                        if (
                            userId === undefined ||
                            guideSessionId === undefined
                        )
                            return
                        Router.push(
                            `${USERS_PATH}/${userId}/guide-sessions/${guideSessionId}`
                        ).catch((error) => {
                            errorAlert({ error })
                        })
                        break
                    }
                    case NotificationAction.GoToResources: {
                        Router.push(RESOURCES_PATH).catch((error) => {
                            errorAlert({ error })
                        })
                        break
                    }
                    case NotificationAction.GoToReminder: {
                        Router.push(REMINDERS_PATH).catch((error) => {
                            errorAlert({ error })
                        })
                        break
                    }
                    case NotificationAction.GoToChats: {
                        Router.push(CHAT_PATH).catch((error) => {
                            errorAlert({ error })
                        })
                        break
                    }
                    case NotificationAction.GoToUsers: {
                        Router.push(USERS_PATH).catch((error) => {
                            errorAlert({ error })
                        })
                        break
                    }
                    case NotificationAction.GoToCallPage: {
                        const userId = notification.notification.data.userId
                        const chatId = notification.notification.data.chatId
                        const kind = notification.notification.data.kind

                        if (
                            userId === undefined ||
                            chatId === undefined ||
                            kind === undefined
                        )
                            return

                        setIsCallPageOpenedByUser(true)
                        Router.push(
                            `${VIDEO_CALL_PATH}/${userId}/${chatId}/${kind}`
                        ).catch((error) => {
                            errorAlert({ error })
                        })
                        break
                    }
                    default: {
                        break
                    }
                }
            }
        )
    }

    async function initPushNotifications() {
        logger(debug)

        if (isOnWeb.value) return

        await addPushNotificationListners()

        // Request permission to use push notifications
        // Will prompt user and return if they granted permission or not
        let permStatus = await PushNotifications.checkPermissions()

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions()
        } else if (permStatus.receive !== 'granted') {
            longToast(i18n.global.t('PushNotifications.error'))
        }

        await PushNotifications.register()
    }

    async function deinitPushNotifications() {
        logger(debug)

        if (isOnAndroid.value || isOnIos.value) {
            await PushNotifications.removeAllListeners()
        }
    }

    return {
        initPushNotifications,
        deinitPushNotifications,
    }
}
