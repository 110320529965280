/**
 * Calculates the median of a range of numbers.
 * @param {number} start - The starting number of the range.
 * @param {number} end - The ending number of the range.
 * @returns {number} - The median value of the range.
 */
export function calculateRangeMedian(start: number, end: number) {
    const count = end - start + 1
    const middleIndex = Math.floor(count / 2)
    let median

    if (count % 2 === 0) {
        median = (start + middleIndex + start + middleIndex - 1) / 2
    } else {
        median = start + middleIndex
    }

    return Math.ceil(median)
}
/**
 *
 * @param {number} time - The time in seconds
 * @returns - The time as a string in hours?:minutes:seconds
 * If hours is 0, it returns no hours place.
 */
export function formatTimeToString(time: number) {
    const hours = Math.floor(time / 3600)
    let minutes = Math.floor((time - hours * 3600) / 60).toString()
    if (hours > 0) minutes = minutes.padStart(2, '0')
    const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, '0')
    const hoursFormatted = hours > 0 ? `${hours}:` : ''
    return `${hoursFormatted}${minutes}:${seconds}`
}
