import { ref, Ref, readonly } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { docData, getCollectionDocs } from '@/utils/firestore'

import { Upload } from '@/models/users/upload'

const debug = false

const userUploads: Ref<Upload[]> = ref([])

export default function (userId?: string) {
    const { viewer } = useViewer()

    function deinitUserUploads() {
        logger(debug)
        userUploads.value = []
    }

    async function fetchUserUpload(uploadId: string) {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const userDocId = userId ?? viewer.value.id

        return firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .doc(uploadId)
            .get()
            .then(docData<Upload>)
    }

    async function fetchUserUploads() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const userDocId = userId ?? viewer.value.id

        const uploadsSnapshot = await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .get()

        userUploads.value = getCollectionDocs<Upload>(uploadsSnapshot)
    }

    async function addUserVideo(file: string, videoName: string) {
        logger(debug, file, videoName)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const userDocId = userId ?? viewer.value.id

        await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .add({
                file: file,
                filetype: 'video',
                name: videoName,
                createdAt: serverTimestamp(),
            })

        await fetchUserUploads()
    }

    async function deleteUpload(uploadId: string) {
        logger(debug, uploadId)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const userDocId = userId ?? viewer.value?.id

        await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .doc(uploadId)
            .delete()

        await fetchUserUploads()
    }

    async function updateUserUploadName(newName: string, uploadId: string) {
        logger(debug, newName, uploadId)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const userDocId = userId ?? viewer.value?.id

        await firestore
            .collection('users')
            .doc(userDocId)
            .collection('uploads')
            .doc(uploadId)
            .update({
                name: newName,
            })

        await fetchUserUploads()
    }

    return {
        userUploads: readonly(userUploads),

        addUserVideo,
        deleteUpload,
        deinitUserUploads,
        fetchUserUpload,
        fetchUserUploads,
        updateUserUploadName,
    }
}
