import { AlertInput, alertController } from '@ionic/vue'

import { loggerError } from '@/utils/debug'
import { getErrorMessage } from '@/utils/errors'

export async function errorAlert(options: { error: unknown }) {
    const { error } = options
    loggerError(error)
    return new Promise<void>((resolve) => {
        alertController
            .create({
                header: 'Error',
                message: getErrorMessage(error),
                buttons: [
                    {
                        text: 'OK',
                        role: 'cancel',
                        handler: () => resolve(),
                    },
                ],
            })
            .then((errorAlert) => errorAlert.present())
    })
}

export async function confirmAlert(options: {
    title: string
    confirmLabel: string
    cancelLabel?: string
    message?: string
    destructive?: boolean
    audioOnConfirm?: HTMLAudioElement
}) {
    const {
        audioOnConfirm,
        cancelLabel,
        confirmLabel,
        destructive,
        message,
        title,
    } = options
    return new Promise<boolean>((resolve) => {
        alertController
            .create({
                header: title,
                message,
                buttons: [
                    {
                        text: cancelLabel ?? 'Cancel',
                        role: 'cancel',
                        handler: () => resolve(false),
                    },
                    {
                        text: confirmLabel,
                        cssClass: destructive ? 'destructive-color' : '',
                        handler: () => {
                            audioOnConfirm?.play()
                            resolve(true)
                        },
                    },
                ],
            })
            .then((confirmAlert) => confirmAlert.present())
    })
}

export async function confirmInputAlert(options: {
    title: string
    confirmLabel: string
    inputs: AlertInput[]
    cancelLabel?: string
    message?: string
    destructive?: boolean
    audioOnConfirm?: HTMLAudioElement
}) {
    const {
        audioOnConfirm,
        cancelLabel,
        confirmLabel,
        destructive,
        inputs,
        message,
        title,
    } = options
    return new Promise<Array<string> | { [key: number]: string }>((resolve) => {
        alertController
            .create({
                header: title,
                message,
                buttons: [
                    {
                        text: cancelLabel ?? 'Cancel',
                        role: 'cancel',
                        handler: () => resolve([]),
                    },
                    {
                        text: confirmLabel,
                        cssClass: destructive ? 'destructive-color' : '',
                        handler: (result) => {
                            audioOnConfirm?.play()
                            resolve(result)
                        },
                    },
                ],
                inputs,
            })
            .then((confirmAlert) => confirmAlert.present())
    })
}
